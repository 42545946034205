import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import client, {
  NotAuthenticatedError,
  NotFoundError,
  BookingTypes,
  ItemIdAssignmentError,
} from "../../client";
import PageLoading from "../../components/PageLoading";
import { useAuth } from "../../hooks/AuthProvider";
import routes from "../../routes";
import {
  BookingTypeTooltip,
  ItemIdTooltip,
  WarehouseShortcodeTooltip,
} from "../../components/Tooltips";
import {
  classNames,
  getInputInvalidFeedback,
  getInputIsInvalid,
} from "../../utils/utils";

export default function EditItemIdAssignment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const [itemIdAssignment, setItemIdAssignment] = useState(undefined);
  const [error, setError] = useState(undefined);

  const fetchItemIdAssignment = async () => {
    try {
      setItemIdAssignment(await client.itemIdAssignments.retrieve(id));
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      if (error instanceof NotFoundError) {
        navigate(routes.notFound, { replace: true });
        return;
      }

      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    fetchItemIdAssignment();
  }, [id]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const newWarehouseId = await client.warehouses.getOrCreate({
        shortcode: itemIdAssignment.warehouseShortcode,
      });
      await client.itemIdAssignments.update({
        ...itemIdAssignment,
        warehouse: newWarehouseId,
      });

      navigate(routes.itemIdAssignments);
      return;
    } catch (error) {
      if (error instanceof ItemIdAssignmentError) {
        setError(error.data);
        throw error;
      }
      console.error(error);
      throw error;
    }
  };

  if (itemIdAssignment === undefined) {
    return <PageLoading />;
  }

  return (
    <div>
      <h4 className="fw-bolder mt-4 mb-5">Edit Assignment based on Item ID</h4>
      <div className="row gap-5">
        <form
          className="col-5"
          onSubmit={(e) =>
            toast.promise(handleFormSubmit(e), {
              pending: "Updating item ID assignment",
              error: "Error while updating item ID assignment",
              success: "Item ID assignment was updated",
            })
          }
        >
          <div className="mb-2">
            <label className="form-label d-flex gap-2" htmlFor="itemIdInput">
              Item ID
              <ItemIdTooltip />
            </label>
            <input
              {...classNames([
                "form-control",
                getInputIsInvalid(error, "itemId") && "is-invalid",
              ])}
              id="itemIdInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              defaultValue={itemIdAssignment.itemId}
              required
              onChange={(e) =>
                setItemIdAssignment({
                  ...itemIdAssignment,
                  itemId: e.target.value,
                })
              }
            />
            {getInputInvalidFeedback(error, "itemId")}
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="warehouseShortCodeInput"
            >
              Warehouse Short Code
              <WarehouseShortcodeTooltip />
            </label>
            <input
              className="form-control"
              id="warehouseShortCodeInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              defaultValue={itemIdAssignment.warehouseShortcode}
              onChange={(e) =>
                setItemIdAssignment({
                  ...itemIdAssignment,
                  warehouseShortcode: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3">
            <label
              className="form-label d-flex gap-2"
              htmlFor="bookingTypeInput"
            >
              Booking Type
              <BookingTypeTooltip />
            </label>
            <Select
              inputId="bookingTypeInput"
              menuPlacement="bottom"
              defaultValue={{
                value: itemIdAssignment.bookingType,
                label: itemIdAssignment.bookingType,
              }}
              options={[
                { value: BookingTypes.EK.value, label: BookingTypes.EK.name },
                { value: BookingTypes.VK.value, label: BookingTypes.VK.name },
              ]}
              onChange={({ value }) =>
                setItemIdAssignment({
                  ...itemIdAssignment,
                  bookingType: value,
                })
              }
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, position: "absolute" }}
              defaultValue={itemIdAssignment.bookingType}
              required
            />
          </div>

          <button className="btn btn-primary me-2" type="submit">
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Save
          </button>
          <Link className="btn btn-secondary" to={routes.itemIdAssignments}>
            <FontAwesomeIcon icon={faXmark} className="me-2" />
            Cancel
          </Link>
        </form>
        <div className="col-4 small fst-italic">
          <p className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-warning me-2"
              style={{ fontSize: "1.1rem" }}
            />
            <b>Priority: Secondary</b>
          </p>
          <p>
            These entries control what happens to manually confirmed webEDI
            positions, where no “Container Alias” is provided. Instead,
            warehouse assignments will be made based on the “Item ID Receiver”
            field of the webEDI.
          </p>
          <p>
            This can be used to easily book specific untagged products to a
            particular warehouse.
          </p>
          <p>
            Hint: If “Container Alias” in webEDI matches with a Container
            assignment, then Item ID assignment will be ignored.
          </p>
        </div>
      </div>
    </div>
  );
}
