import qs from "qs";

import { reverse } from "../../client/constants";
import { Resource } from "../../client/resources";
import {
  BadRequestError,
  ContainerAssignmentError,
  CreateContainerAssignmentError,
  CreateItemIdAssignmentError,
  ItemIdAssignmentError,
} from "../../client/errors";
import {
  ContainerAssignmentListModel,
  WarehouseModel,
  ContainerAssignmentModel,
  ItemIdAssignmentListModel,
  ItemIdAssignmentModel,
  ReaderModel,
  ReadListModel,
} from "./models";
import { ReadingCountModel } from "../../client/models";

export class Warehouses extends Resource {
  async list({ shortcode }) {
    const response = await this._instance.get(reverse("warehouseList"), {
      params: { shortcode },
    });

    return response.data.map((warehouse) => new WarehouseModel(warehouse));
  }

  async create({ shortcode }) {
    const response = await this._instance.post(reverse("warehouseList"), {
      shortcode,
    });
    return new WarehouseModel(response.data);
  }

  async getOrCreate({ shortcode }) {
    const warehouses = await this.list({
      shortcode,
    });

    let warehouseId;
    if (warehouses.length == 1) {
      warehouseId = warehouses[0].id;
    } else {
      // If warehouse with such code does not exist then create
      const warehouse = await this.create({ shortcode });
      warehouseId = warehouse.id;
    }

    return warehouseId;
  }
}

export class ContainerAssignments extends Resource {
  async list({ search, page = 1, pageSize = 10 }) {
    const response = await this._instance.get(
      reverse("containerAssignmentList"),
      {
        params: {
          ...(search && { search }),
          ...(page && { page }),
          ...(pageSize && { page_size: pageSize }),
        },
      }
    );

    return new ContainerAssignmentListModel(response.data);
  }

  async create({
    warehouse,
    elementString,
    containerAlias,
    bookingType,
    readerMac,
    inHouse,
    lastContent,
    autoUpdateOnArrival,
  }) {
    try {
      await this._instance.post(reverse("containerAssignmentList"), {
        warehouse,
        element_string: elementString,
        container_alias: containerAlias,
        booking_type: bookingType,
        reader_mac: readerMac,
        in_house: inHouse,
        last_content: lastContent,
        auto_update_on_arrival: autoUpdateOnArrival,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new CreateContainerAssignmentError(error.data);
      }
      throw error;
    }
  }

  async retrieve(id) {
    const response = await this._instance.get(
      reverse("containerAssignmentDetail", { id })
    );
    return new ContainerAssignmentModel(response.data);
  }

  async update({
    id,
    elementString,
    warehouse,
    containerAlias,
    bookingType,
    readerMac,
    inHouse,
    lastContent,
    autoUpdateOnArrival,
  }) {
    try {
      await this._instance.patch(reverse("containerAssignmentDetail", { id }), {
        warehouse,
        element_string: elementString,
        container_alias: containerAlias,
        booking_type: bookingType,
        reader_mac: readerMac,
        in_house: inHouse,
        last_content: lastContent,
        auto_update_on_arrival: autoUpdateOnArrival,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new ContainerAssignmentError(error.data);
      }

      throw error;
    }
  }

  async patch({ id,  inHouse}) {
    try {
      await this._instance.patch(reverse("containerAssignmentDetail", { id }), {
        in_house: inHouse,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new ContainerAssignmentError(error.data);
      }

      throw error;
    }
  }

  async destroy(id) {
    await this._instance.delete(reverse("containerAssignmentDetail", { id }));
  }

  async purge(ids) {
    if (ids) {
      await this._instance.delete(reverse("containerAssignmentList"), {
        params: { id__in: ids.toString() },
      });
    }
  }
}

export class ItemIdAssignments extends Resource {
  async list({ search, itemId, itemIdNotEqual, page = 1, pageSize = 10 }) {
    const response = await this._instance.get(reverse("itemIdAssignmentList"), {
      params: {
        ...(search && { search }),
        ...(itemId && { item_id: itemId }),
        ...(itemIdNotEqual && { item_id__neq: itemIdNotEqual }),
        ...(page && { page }),
        ...(pageSize && { page_size: pageSize }),
      },
    });

    return new ItemIdAssignmentListModel(response.data);
  }

  async create({ warehouse, itemId, bookingType }) {
    try {
      await this._instance.post(reverse("itemIdAssignmentList"), {
        warehouse,
        item_id: itemId,
        booking_type: bookingType,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new CreateItemIdAssignmentError(error.data);
      }

      throw error;
    }
  }

  async retrieve(id) {
    const response = await this._instance.get(
      reverse("itemIdAssignmentDetail", { id })
    );
    return new ItemIdAssignmentModel(response.data);
  }

  async update({ id, warehouse, itemId, bookingType }) {
    try {
      await this._instance.patch(reverse("itemIdAssignmentDetail", { id }), {
        warehouse,
        item_id: itemId,
        booking_type: bookingType,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new ItemIdAssignmentError(error.data);
      }
      throw error;
    }
  }

  async destroy(id) {
    await this._instance.delete(reverse("itemIdAssignmentDetail", { id }));
  }

  async purge(ids) {
    if (ids) {
      await this._instance.delete(reverse("itemIdAssignmentList"), {
        params: { id__in: ids.toString() },
      });
    }
  }
}

export class DefaultAssignments extends Resource {
  async list({search, itemId, itemIdNotEqual, page = 1, pageSize = 10}) {
    const response = await this._instance.get(reverse("itemIdAssignmentList"), {
      params: {
        ...(search && {search}),
        ...(itemId && {item_id: itemId}),
        ...(itemIdNotEqual && {item_id__neq: itemIdNotEqual}),
        ...(page && {page}),
        ...(pageSize && {page_size: pageSize}),
      },
    });

    return new ItemIdAssignmentListModel(response.data);
  }
}



// TODO clean up code
class FullScan {
  constructor(data) {
    this.epc_hex = data.epc_hex;
    this.reader_mac_address = data.reader_mac_address;
    this.reader_ant = data.reader_ant;
    this.last_measured_at = new Date(data.last_measured_at);
    this.last_rssi = data.last_rssi;
    this.count = data.count;
  }
}


export class Readers extends Resource {
  async getOnlineReaders() {
    const response = await this._instance.get(reverse("deviceList"));
    return response.data.map((reader) => new ReaderModel(reader));
  }

  async getApprovedReads({ timeDelta, readerIds, page = 1, pageSize = 10 }) {
    const response = await this._instance.get(reverse("approvedReads"), {
      params: {
        timedelta: timeDelta,
        ...(readerIds.length > 0 && { reader_id: readerIds }),
        page,
        page_size: pageSize,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });

    return new ReadListModel(response.data);
  }

  async getFullScan({ readerIds }) {
    console.log(readerIds);
    if (readerIds?.length !== 1) {
      return [];
    }
    const response = await this._instance.get(reverse("readerFullScan") + "/" + readerIds[0] + "/");
    return response.data.map((result) => new FullScan(result));
  }

  async getApprovedReadCount({ timeDelta, readerIds }) {
    const response = await this._instance.get(reverse("approvedReadCount"), {
      params: {
        timedelta: timeDelta,
        ...(readerIds.length > 0 && { reader_id: readerIds }),
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });

    return response.data.map((result) => new ReadingCountModel(result));
  }

  async getApprovedReadCountSum({ timeDelta, readerIds }) {
    const response = await this._instance.get(reverse("approvedReadCountSum"), {
      params: {
        timedelta: timeDelta,
        ...(readerIds.length > 0 && { reader_id: readerIds }),
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });

    return response.data;
  }
}
