import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import routes from "../../routes";
import client, {
  BadRequestError,
  BookingTypes,
  NotAuthenticatedError,
} from "../../client";
import {
  ElementStringTooltip,
  WarehouseShortcodeTooltip,
  ContainerAliasTooltip,
  BookingTypeTooltip,
  ReaderMacTooltip,
  InHouseTooltip,
  LastContentTooltip,
  AutoUpdateOnArrivalTooltip,
} from "../../components/Tooltips";
import { useAuth } from "../../hooks/AuthProvider";
import {
  classNames,
  getInputIsInvalid,
  getInputInvalidFeedback,
} from "../../utils/utils";

export default function CreateContainerAssignment() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [readerMacOptions, setReaderMacOptions] = useState(undefined);

  const [elementString, setElementString] = useState("");
  const [warehouseShortcode, setWarehouseShortcode] = useState("");
  const [containerAlias, setContainerAlias] = useState("");
  const [inHouse, setInHouse] = useState(true);
  const [autoUpdateOnArrival, setAutoUpdateOnArrival] = useState(true);
  const [bookingType, setBookingType] = useState("EK");
  const [readerMac, setReaderMac] = useState("");
  const [lastContent, setLastContent] = useState("");

  const [submitError, setSubmitError] = useState(undefined);

  const fetchReaderMacOptions = async () => {
    try {
      setReaderMacOptions(await client.readers.getOnlineReaders());
    } catch (error) {
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    fetchReaderMacOptions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const warehouseId = await client.warehouses.getOrCreate({
        shortcode: warehouseShortcode,
      });
      await client.containerAssignments.create({
        warehouse: warehouseId,
        elementString,
        containerAlias,
        bookingType,
        readerMac,
        inHouse,
        lastContent,
        autoUpdateOnArrival,
      });

      navigate(routes.containerAssignments);
      return;
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      } else if (error instanceof BadRequestError) {
        setSubmitError(error);
        throw error;
      }

      console.error(error);
      throw error;
    }
  };

  return (
    <div>
      <h4 className="fw-bolder mt-4 mb-5">
        Create new Assignment based on Container/Tag Info
      </h4>
      <div className="row gap-5">
        <form
          className="col-5"
          onSubmit={(e) =>
            toast.promise(handleSubmit(e), {
              pending: "Creating new container assignment",
              error: "Error while creating container assignment",
              success: "Container assignment was created",
            })
          }
        >
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="elementStringInput"
            >
              GS1 Element String
              <ElementStringTooltip />
            </label>
            <input
              {...classNames([
                "form-control",
                getInputIsInvalid(submitError?.data, "elementString") &&
                  "is-invalid",
              ])}
              id="elementStringInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              onChange={(e) => setElementString(e.target.value)}
            />
            {getInputInvalidFeedback(submitError?.data, "elementString")}
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="warehouseShortCodeInput"
            >
              Warehouse Short Code
              <WarehouseShortcodeTooltip />
            </label>
            <input
              // className="form-control"
              {...classNames([
                "form-control",
                getInputIsInvalid(submitError?.data, "warehouse") &&
                "is-invalid",
              ])}
              id="warehouseShortCodeInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              onChange={(e) => setWarehouseShortcode(e.target.value)}
            />
            {getInputInvalidFeedback(submitError?.data, "warehouse")}
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="containerAliasInput"
            >
              Container Alias
              <ContainerAliasTooltip />
            </label>
            <input
              // className="form-control"
              {...classNames([
                "form-control",
                getInputIsInvalid(submitError?.data, "containerAlias") &&
                  "is-invalid",
              ])}
              id="containerAliasInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              onChange={(e) => setContainerAlias(e.target.value)}
            />
            {getInputInvalidFeedback(submitError?.data, "containerAlias")}
          </div>
          <div className="row ">
            <div className="mb-2 col-6">
              <label
                className="form-label d-flex gap-2"
                htmlFor="inHouseInput"
              >
                In House
                <InHouseTooltip />
              </label>
              <input
                type="checkbox"
                defaultChecked={inHouse}
                onChange={() => {
                  setInHouse(!inHouse)
                }}
              />
            </div>
            <div className="mb-2 col-6">
              <label
                className="form-label d-flex gap-2"
                htmlFor="autoUpdateOnArrivalInput"
              >
                Automatic Update on Arrival
                <AutoUpdateOnArrivalTooltip />
              </label>
              <input
                type="checkbox"
                defaultChecked={autoUpdateOnArrival}
                onChange={() => {
                  setAutoUpdateOnArrival(!autoUpdateOnArrival)
                }}
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="lastContentInput"
            >
              Last Content
              <LastContentTooltip />
            </label>
            <input
              {...classNames([
                "form-control",
                getInputIsInvalid(submitError?.data, "lastContent") &&
                "is-invalid",
              ])}
              id="lastContentInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              onChange={(e) => setLastContent(e.target.value)}
            />
            {getInputInvalidFeedback(submitError?.data, "lastContent")}
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="bookingTypeInput"
            >
              Booking Type
              <BookingTypeTooltip />
            </label>
            <Select
              inputId="bookingTypeInput"
              menuPlacement="bottom"
              defaultValue={{
                value: BookingTypes.EK.value,
                label: BookingTypes.EK.name,
              }}
              options={[
                { value: BookingTypes.EK.value, label: BookingTypes.EK.name },
                { value: BookingTypes.VK.value, label: BookingTypes.VK.name },
              ]}
              onChange={({ value }) => setBookingType(value)}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, position: "absolute" }}
              defaultValue={bookingType}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label d-flex gap-2" htmlFor="readerMacInput">
              Reader MAC
              <ReaderMacTooltip />
            </label>
            <Select
              inputId="readerMacInput"
              menuPlacement="bottom"
              closeMenuOnSelect
              isLoading={readerMacOptions === undefined}
              required
              options={readerMacOptions?.map((readerMac) => ({
                value: readerMac.macAddress,
                label: readerMac.macAddress,
              }))}
              onChange={({ value }) => setReaderMac(value)}
            />
            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: "absolute" }}
              autoComplete="off"
              defaultValue={readerMac}
              required
            />
          </div>
          <button className="btn btn-primary me-2" type="submit">
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Save
          </button>
          <Link className="btn btn-secondary" to={routes.containerAssignments}>
            <FontAwesomeIcon icon={faXmark} className="me-2" />
            Cancel
          </Link>
        </form>
        <div className="col-4 small fst-italic">
          <p className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-warning me-2"
              style={{ fontSize: "1.1rem" }}
            />
            <b>Priority: Primary</b>
          </p>
          <p>
            These entries control what happens to automatically and manually
            confirmed webEDI positions, where “Container Alias” is provided.
          </p>
          <p>
            This can be used to easily book specifically tagged (multi way)
            containers to a particular warehouse.
          </p>
          <p>
            Hint: As primary priority, container assignments override
            assignments based on Item IDs.
          </p>
        </div>
      </div>
    </div>
  );
}
