const Jumbotron = ({ children, ...rest }) => {
  return (
    <div
      {...{
        ...rest,
        className: [
          "jumbotron",
          ...(rest.className ? [rest.className] : []),
        ].join(" "),
      }}
    >
      {children}
    </div>
  );
};

export default Jumbotron;
