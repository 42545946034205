import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import client from "../../client";
import routes from "../../routes";
import PageLoading from "../../components/PageLoading";
import AdditionalLabels from "../components/AdditionalLabels";
import { NotFoundError } from "../../client/errors";
import {
  NoteTooltip,
  TotalSerialsTooltip,
  LabelSerialsTooltip,
  StartSerialTooltip,
  EndSerialTooltip,
  RowsPerSerialTooltip,
  AliasPrefixTooltip,
  AliasStartIncrementTooltip,
  AliasSuffixTooltip,
} from "../../components/HelpTooltip";

const SimpleModeCreate = ({ grai, graiLastSeries, onCreate }) => {
  const [note, setNote] = useState("");
  const [totalNewSerials, setTotalNewSerials] = useState(undefined);
  const [label1, setLabel1] = useState("");
  const [label2, setLabel2] = useState("");
  const [label3, setLabel3] = useState("");

  const lastSeriesEndSerial = graiLastSeries ? graiLastSeries.endSerial : 0;
  const startSerial = lastSeriesEndSerial + 1;
  const endSerial = lastSeriesEndSerial + 1 + Number(totalNewSerials);

  const createSeries = async () => {
    try {
      await client.graiSeries.create({
        grai: grai.id,
        startSerial,
        endSerial,
        note,
        label1,
        label2,
        label3,
        outputRowFactor: 1,
      });

      onCreate();
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // Event handlers
  const handleNoteChange = (e) => setNote(e.target.value);
  const handleTotalNewSerialsChange = (e) => setTotalNewSerials(e.target.value);
  const handleLabel1Change = (e) => setLabel1(e.target.value);
  const handleLabel2Change = (e) => setLabel2(e.target.value);
  const handleLabel3Change = (e) => setLabel3(e.target.value);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    toast.promise(createSeries, {
      pending: "Creating a series",
      error: "Error while series creation",
      success: "Series was successfully created",
    });
  };

  // Rendering
  //

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-3 col-4">
        <label className="form-label" htmlFor="graiSeriesCreateGtinInput">
          GRAI
        </label>
        <input
          className="form-control col-3"
          id="graiSeriesCreateGtinInput"
          readOnly
          value={grai?.grai}
        />
      </div>
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="graiSeriesCreateNoteInput"
        >
          Note
          <NoteTooltip />
        </label>
        <input
          id="graiSeriesCreateNoteInput"
          className="form-control"
          name="note"
          type="text"
          maxLength={255}
          onChange={handleNoteChange}
          autoComplete="off"
        />
      </div>
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="graiSeriesCreateTotalNewSerialsInput"
        >
          Total New Serials
          <TotalSerialsTooltip />
        </label>
        <input
          id="graiSeriesCreateTotalNewSerialsInput"
          className="form-control"
          name="totalNewSerials"
          type="number"
          min={1}
          max={50_000}
          onChange={handleTotalNewSerialsChange}
          required
        />
        {Number(totalNewSerials) > 0 && (
          <div className="form-text">
            Start serial: {startSerial}. End serial: {endSerial}.
          </div>
        )}
      </div>
      <div className="mb-2 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="graiSeriesCreateLabel1Input"
        >
          Text Label
          <LabelSerialsTooltip />
        </label>
        <input
          id="graiSeriesCreateLabel1Input"
          className="form-control"
          name="label1"
          maxLength={255}
          onChange={handleLabel1Change}
          required
          autoComplete="off"
        />
      </div>
      <AdditionalLabels
        onLabel2Change={handleLabel2Change}
        onLabel3Change={handleLabel3Change}
      />
      <button className="btn btn-primary" type="submit">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create
      </button>
    </form>
  );
};

const AdvanceModeCreate = ({ grai, graiLastSeries, onCreate }) => {
  const [note, setNote] = useState("");
  const lastSeriesEndSerial = graiLastSeries ? graiLastSeries.endSerial : 0;
  const [startSerial, setStartSerial] = useState(lastSeriesEndSerial + 1);
  const [endSerial, setEndSerial] = useState(lastSeriesEndSerial + 2);
  const [label1, setLabel1] = useState("");
  const [label2, setLabel2] = useState("");
  const [label3, setLabel3] = useState("");
  const [rowsPerSerial, setRowsPerSerial] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  // Alias fields
  const [aliasPrefix, setAliasPrefix] = useState("");
  const [aliasStartIncrement, setAliasStartIncrement] = useState(undefined);
  const [aliasSuffix, setAliasSuffix] = useState("");

  // Computed values
  //

  const errors = { endSerial: [] };

  if (endSerial <= startSerial) {
    errors.endSerial.push("End serial must be greater than starting serial.");
  }

  // Whether alias was started to be entered (useful if one wants to enforce requirement of a field if another
  // field was entered someone to add other fields)
//  const aliasEntered =
//    aliasPrefix !== "" || aliasStartIncrement !== "" || aliasSuffix !== "";

  const createSeries = async () => {
    try {
      await client.graiSeries.create({
        grai: grai.id,
        startSerial,
        endSerial,
        note,
        label1,
        label2,
        label3,
        outputRowFactor: rowsPerSerial,
        aliasPrefix,
        aliasStartIncrement,
        aliasSuffix,
      });
      onCreate();
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleNoteChange = (e) => setNote(e.target.value);
  const handleLabel1Change = (e) => setLabel1(e.target.value);
  const handleLabel2Change = (e) => setLabel2(e.target.value);
  const handleLabel3Change = (e) => setLabel3(e.target.value);
  const handleStartSerialChange = (e) => setStartSerial(e.target.value);
  const handleEndSerialChange = (e) => setEndSerial(e.target.value);
  const handleRowsPerSerialChange = (e) => setRowsPerSerial(e.target.value);

  const handleAliasPrefixChange = (e) => setAliasPrefix(e.target.value);
  const handleAliasStartIncrementChange = (e) =>
    setAliasStartIncrement(e.target.value);
  const handleAliasSuffixChange = (e) => setAliasSuffix(e.target.value);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (errors.endSerial.length > 0) {
      setSubmitted(true);
      return;
    }

    toast.promise(createSeries, {
      pending: "Creating a series",
      error: "Error while series creation",
      success: "Series was successfully created",
    });
  };

  // Rendering
  //

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-3 col-4">
        <label className="form-label" htmlFor="graiSeriesCreateGtinInput">
          GRAI
        </label>
        <input
          className="form-control col-3"
          id="graiSeriesCreateGtinInput"
          readOnly
          value={grai?.grai}
        />
      </div>
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="graiSeriesCreateNoteInput"
        >
          Note
          <NoteTooltip />
        </label>
        <input
          id="graiSeriesCreateNoteInput"
          className="form-control"
          name="note"
          type="text"
          maxLength={255}
          onChange={handleNoteChange}
          autoComplete="off"
        />
      </div>
      <div className="d-flex gap-3">
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="graiSeriesCreateStartSerialInput"
          >
            Start Serial
            <StartSerialTooltip />
          </label>
          <input
            id="graiSeriesCreateStartSerialInput"
            className="form-control"
            name="startSerial"
            type="number"
            min={0}
            defaultValue={lastSeriesEndSerial + 1}
            required
            onChange={handleStartSerialChange}
          />
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="graiSeriesCreateEndSerialInput"
          >
            End Serial
            <EndSerialTooltip />
          </label>
          <input
            id="graiSeriesCreateEndSerialInput"
            className={[
              "form-control",
              ...(errors.endSerial.length > 0 && submitted
                ? ["is-invalid"]
                : [""]),
            ].join(" ")}
            name="endSerial"
            type="number"
            min={1}
            defaultValue={lastSeriesEndSerial + 2}
            required
            onChange={handleEndSerialChange}
          />
          {submitted &&
            errors.endSerial.map((error, index) => (
              <div className="invalid-feedback" key={index}>
                {error}
              </div>
            ))}
          {endSerial > startSerial && (
            <div className="form-text">
              Total serials: {endSerial - startSerial + 1}.
            </div>
          )}
        </div>
      </div>
      <div className="d-flex gap-3">
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="graiSeriesCreateAliasPrefixInput"
          >
            Alias Prefix
            <AliasPrefixTooltip />
          </label>
          <input
            id="graiSeriesCreateAliasPrefixInput"
            className="form-control"
            name="aliasPrefix"
            type="text"
            // required={aliasEntered}
            onChange={handleAliasPrefixChange}
          />
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="graiSeriesCreateAliasStartIncrementInput"
          >
            Alias Start Increment
            <AliasStartIncrementTooltip />
          </label>
          <input
            id="graiSeriesCreateAliasStartIncrementInput"
            className="form-control"
            name="aliasStartIncrement"
            type="number"
            min={0}
            // required={aliasEntered}
            onChange={handleAliasStartIncrementChange}
          />
          {endSerial > startSerial && aliasStartIncrement !== undefined && (
            <div className="form-text">
              Start alias:
              {aliasPrefix}
              {aliasStartIncrement}
              {aliasSuffix}.
              End alias:
              {aliasPrefix}
              {parseInt(aliasStartIncrement) + (endSerial - startSerial)}
              {aliasSuffix}.
            </div>
          )}
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="graiSeriesCreateAliasSuffixInput"
          >
            Alias Suffix
            <AliasSuffixTooltip />
          </label>
          <input
            id="graiSeriesCreateAliasSuffixInput"
            className="form-control"
            name="aliasSuffix"
            type="text"
            // required={aliasEntered}
            onChange={handleAliasSuffixChange}
          />
        </div>
      </div>
      <div className="mb-2 col-4">
        <label className="form-label" htmlFor="graiSeriesCreateLabel1Input">
          Text Label
        </label>
        <input
          id="graiSeriesCreateLabel1Input"
          className="form-control"
          name="label1"
          maxLength={255}
          onChange={handleLabel1Change}
          required
          autoComplete="off"
        />
      </div>
      <AdditionalLabels
        onLabel2Change={handleLabel2Change}
        onLabel3Change={handleLabel3Change}
      />
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="graiSeriesCreateRowsPerSerialInput"
        >
          Rows per Serial
          <RowsPerSerialTooltip />
        </label>
        <input
          id="graiSeriesCreateRowsPerSerialInput"
          className="form-control"
          name="rowsPerSerial"
          type="number"
          required
          min={1}
          max={30}
          defaultValue={1}
          onChange={handleRowsPerSerialChange}
        />
      </div>
      <button className="btn btn-primary" type="submit">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create
      </button>
    </form>
  );
};

const GraiSeriesCreate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isSimpleMode, setIsSimpleMode] = useState(true);
  const [grai, setGrai] = useState(undefined);
  const [graiLastSeries, setGraiLastSeries] = useState(undefined);

  const fetchGrai = async () => {
    try {
      setGrai(await client.grais.retrieve(id));
    } catch (error) {
      if (error instanceof NotFoundError) {
        navigate(routes.notFound, { replace: true });
        return;
      }
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    setGrai(undefined);
    fetchGrai();
  }, [id]);

  const fetchGraiLastSeries = async () => {
    try {
      const series = await client.graiSeries.list({ grai: id });
      if (series.count > 0) {
        setGraiLastSeries(series.results[0]);
      } else {
        setGraiLastSeries(null);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    if (grai !== undefined) {
      fetchGraiLastSeries();
    }
  }, [grai]);

  // Rendering
  //

  if (grai === undefined) {
    return <PageLoading />;
  }

  const handleSeriesCreate = () => {
    navigate(routes.graiSeriesOverview(id));
  };

  return (
    <div>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={routes.graiOverview}>GRAIs</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={routes.graiSeriesOverview(id)}>Series</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={routes.graiSeriesOverview(id)}>Create</Link>
          </li>
        </ol>
      </nav>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">
        Create new GRAI Print Series
      </h5>
      <div className="d-flex align-items-center gap-2 mb-3">
        <div className={[...(isSimpleMode ? ["fw-bold"] : [])].join(" ")}>
          Simple
        </div>
        <div className="form-check form-switch-lg form-switch ps-2 me-2">
          <input
            className="form-check-input fs-3 ms-0 ps-0"
            type="checkbox"
            role="switch"
            id="uiSimpleAdvanceSwitch"
            onChange={() => setIsSimpleMode(!isSimpleMode)}
          />
        </div>
        <div className={[...(!isSimpleMode ? ["fw-bold"] : [])].join(" ")}>
          Advanced
        </div>
      </div>
      {isSimpleMode ? (
        <SimpleModeCreate
          grai={grai}
          graiLastSeries={graiLastSeries}
          onCreate={handleSeriesCreate}
        />
      ) : (
        <AdvanceModeCreate
          grai={grai}
          graiLastSeries={graiLastSeries}
          onCreate={handleSeriesCreate}
        />
      )}
    </div>
  );
};

export default GraiSeriesCreate;
