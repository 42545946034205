import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { faUser, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth } from "../hooks/AuthProvider";
import routes from "../routes";

export default function Header() {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await auth.logout();
    // Navigate to the login page is a little bit faster than rerendering + rerouting navigation
    navigate(routes.login, { replace: true });
    toast.success("Successfully logged-out");
  };

  return (
    <div className="d-flex justify-content-end mt-3 mb-4">
      <div className="dropdown">
        <div
          className="profile d-flex align-items-center dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
        >
          <div className="fs-5 fw-bolder me-3">{auth.user.username}</div>
          <FontAwesomeIcon className="fs-4 me-2" icon={faUser} />
        </div>
        <ul className="dropdown-menu dropdown-menu-end">
          <li>
            <h6 className="dropdown-header">Account</h6>
          </li>
          <li>
            <Link className="dropdown-item" to={routes.profile}>
              Profile
            </Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <button className="dropdown-item" onClick={handleLogout}>
              <FontAwesomeIcon className="me-2" icon={faRightFromBracket} />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
