export default function IconToggleButton({
  on = true,
  onToggle = () => {},
  iconOn,
  iconOff,
}) {
  return (
    <button
      className="btn btn-outline-secondary"
      onClick={() => onToggle()}
      style={{ width: 60 }}
    >
      {on ? iconOn : iconOff}
    </button>
  );
}
