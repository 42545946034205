import { Link } from "react-router-dom";

import routes from "../routes";
import { useLocation } from "react-router-dom";
import Jumbotron from "../serialization/components/Jumbotron";
import Logo from "../components/Logo";

const Contact = () => {
  const location = useLocation();
  const username = location.state?.username ? (
    <>
      : &quot;<b>{location.state?.username}</b>&quot;
    </>
  ) : null;

  return (
    <Jumbotron>
      <Logo />
      <h3 className="mb-3">Contact</h3>
      <div>
        <p>
          Please contact admin for activation and send your username{username}.
          If your account is activated visit login page.
        </p>
        <Link className="btn btn-primary mx-auto" to={routes.login}>
          Login
        </Link>
      </div>
    </Jumbotron>
  );
};

export default Contact;
