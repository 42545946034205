import { useAuth } from "../hooks/AuthProvider";
import Logo from "./Logo";

export default function AppLoading({ children }) {
  const auth = useAuth();

  if (auth.user === undefined) {
    return (
      <div className="jumbotron pb-5">
        <Logo />
        <h3 className="mb-3">App is loading...</h3>
        <p>Please wait until the app is loaded.</p>
      </div>
    );
  }

  return children;
}
