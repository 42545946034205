import { useState, useEffect, forwardRef, useRef } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
} from "react-table";
import Select from "react-select";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import PlaceHolderTable from "./PlaceHolderTable";
import useUpdateEffect from "../../hooks/useUpdateEffect";

const IndeterminateCheckbox = forwardRef(_IndeterminateCheckbox);

// Table component that managing data fetching, placeholder/loading rendering
// and data operations (filtering, pagination, row selection).
//
// The outer component is responsible for refetching data based on the
// filter changes.
// EDIT: table requires count to be passed (data must be already fetched => table can't render placeholders)
export default function Table({
  data,
  columns,
  controlledPageCount,
  controlledGlobalFilter,
  controlledPageSize,
  controlledPageIndex,
  onChange,
  onRowSelectChange,
  footer,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // visibleColumns,
    state: { globalFilter, pageIndex, pageSize, selectedRowIds }, // internal table states (plugins)
    // Filtering
    // preGlobalFilteredRows,
    setGlobalFilter,
    // Pagination
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        globalFilter: controlledGlobalFilter,
        pageIndex: controlledPageIndex,
        pageSize: controlledPageSize,
      },
      // External (server-side) data manipulation (filtering/pagination/...)
      manualGlobalFilter: true,
      autoResetGlobalFilter: false,
      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  // Don't run on initial mount
  useUpdateEffect(() => {
    onChange({ globalFilter, pageSize, pageIndex });
  }, [pageIndex]);

  // If search or size is changed than go back to the first page
  useUpdateEffect(() => {
    onChange({ globalFilter, pageSize, pageIndex: 1 });
  }, [globalFilter, pageSize]);

  // useEffect(() => {
  //   // TODO
  // }, [count]);

  useUpdateEffect(() => {
    onRowSelectChange(
      Object.keys(selectedRowIds).map((index) => data[index].id)
    );
  }, [selectedRowIds]);

  if (data === undefined) {
    return (
      <PlaceHolderTable
        headers={columns.map((column) => column.Header)}
        size={pageSize}
      />
    );
  }

  return (
    <div className="table-component">
      <div className="d-flex justify-content-end mt-2 mb-3">
        <GlobalFilter
          data={data}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, groupIndex) => (
            <tr key={groupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);

            return (
              <tr key={rowIndex} {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td key={cellIndex} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-between align-items-center">
        <div>{footer}</div>
        <div>
          Page {pageIndex} of {controlledPageCount}
        </div>
        <div className="d-flex gap-2">
          <Pagination
            pageIndex={pageIndex}
            pageCount={controlledPageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            gotoPage={(index) =>
              onChange({ globalFilter, pageSize, pageIndex: index })
            }
          />
          <PageSizeSelect pageSize={pageSize} setPageSize={setPageSize} />
        </div>
      </div>
    </div>
  );
}

// Component responsible for managing global filter state interface
function GlobalFilter({
  data,
  // preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  // State for rendering (default value)
  const [value, setValue] = useState(globalFilter);

  const count = data.length;
  // Set inverval between input change and filtering
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  return (
    <div className="d-flex align-items-center">
      <div className="me-3" style={{ whiteSpace: "nowrap" }}>
        <FontAwesomeIcon icon={faFilter} className="me-2" />
        Search in {count} records:
      </div>
      <input
        autoFocus
        type="text"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className="form-control"
      />
    </div>
  );
}

function Pagination({ pageIndex, pageCount, previousPage, gotoPage }) {
  // *1* - 2 - 3 - 4 - 5
  // 3 - 4 - *5* - 6 - 7 (page count)
  const PAGES_DISPLAYED = 5;
  const MIDDLE_POSITION = Math.ceil(PAGES_DISPLAYED / 2);
  const LAST_MIDDLE_POSITION = pageCount - MIDDLE_POSITION;

  let startIndex = 1;
  if (pageIndex > MIDDLE_POSITION) {
    startIndex = pageIndex - MIDDLE_POSITION + 1;

    if (pageIndex > LAST_MIDDLE_POSITION) {
      startIndex = pageCount - PAGES_DISPLAYED + 1;
    }
  }

  return (
    <div>
      <nav>
        <ul className="pagination">
          <li
            className={[
              "page-item",
              ...(pageIndex !== 1 ? [] : ["disabled"]),
            ].join(" ")}
          >
            <a
              className="page-link"
              onClick={() => previousPage()}
              role="button"
            >
              &laquo;
            </a>
          </li>
          {_.range(startIndex, PAGES_DISPLAYED + 1).map((index) => (
            <li
              key={index}
              className={[
                "page-item",
                ...(index === pageIndex
                  ? ["active"]
                  : index > pageCount
                  ? ["disabled"]
                  : []),
              ].join(" ")}
              onClick={() => {
                if (index <= pageCount) {
                  gotoPage(index);
                }
              }}
            >
              <a className="page-link" role="button">
                {index}
              </a>
            </li>
          ))}
          <li
            className={[
              "page-item",
              ...(pageIndex !== pageCount ? [] : ["disabled"]),
            ].join(" ")}
          >
            <a
              className="page-link"
              onClick={() => {
                if (pageIndex !== pageCount) {
                  gotoPage(pageIndex + 1);
                }
              }}
              role="button"
            >
              &raquo;
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

function PageSizeSelect({ pageSize, setPageSize }) {
  const sizes = [5, 10, 20, 30, 40, 50];

  return (
    <Select
      menuPlacement="top"
      defaultValue={{ value: pageSize, label: pageSize }}
      options={sizes.map((size) => ({ value: size, label: size }))}
      onChange={(newOption) => setPageSize(newOption.value)}
    />
  );
}

function _IndeterminateCheckbox({ indeterminate, ...rest }, ref) {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input
      className="form-check-input"
      type="checkbox"
      ref={resolvedRef}
      {...rest}
    />
  );
}
