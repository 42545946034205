import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import routes from "../../routes";
import client from "../../client";
import { FilterValues } from "../client/models";
import PageLoading from "../../components/PageLoading";
import { NotFoundError } from "../../client/errors";
import {
  BaseNumberTooltip,
  AssetTypeTooltip,
  GraiFilterValueTooltip,
} from "../../components/HelpTooltip";

const GraiEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [grai, setGrai] = useState(undefined);

  // Event handlers
  //

  const fetchGrai = async () => {
    try {
      setGrai(await client.grais.retrieve(id));
    } catch (error) {
      if (error instanceof NotFoundError) {
        navigate(routes.notFound);
        return;
      }

      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    setGrai(undefined);
    fetchGrai();
  }, [id]);

  const handleNameChange = (e) => setGrai({ ...grai, name: e.target.value });

  const editGrai = async () => {
    try {
      await client.grais.update(grai.id, grai);
      navigate(routes.graiOverview);

      return; // resolve promise
    } catch (error) {
      console.error(error);

      throw error; // reject promise
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    toast.promise(editGrai, {
      pending: "Updating GRAI",
      success: "GRAI updated successfully",
      error: "Error while updating",
    });
  };

  // Rendering
  //

  // Display page loading (app is already loaded)
  if (grai === undefined) {
    return <PageLoading />;
  }

  return (
    <div className="grai-edit">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={routes.graiOverview}>GRAIs</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={routes.graiEdit(id)}>Edit</Link>
          </li>
        </ol>
      </nav>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">Edit grai entry</h5>
      <form className="grai-edit__form" onSubmit={handleFormSubmit}>
        <div className="d-flex gap-3 mb-3">
          <div>
            <input
              className="form-control"
              style={{ marginTop: 30 }}
              readOnly
              value={0}
            />
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="graiCreateBaseNumberInput"
            >
              Base Number
              <BaseNumberTooltip />
            </label>
            <input
              id="graiCreateBaseNumberInput"
              className="form-control"
              readOnly
              value={grai.companyPrefix}
            />
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="graiCreateAssetTypeInput"
            >
              Asset type
              <AssetTypeTooltip />
            </label>
            <input
              id="graiCreateAssetTypeInput"
              className="form-control"
              readOnly
              value={grai.assetType}
            />
          </div>
          <div>
            <label className="form-label" htmlFor="graiCreateCheckDigitInput">
              Check Digit
            </label>
            <input
              id="graiCreateCheckDigitInput"
              className="form-control"
              readOnly
              value={grai.checkDigit}
            />
          </div>
        </div>
        <div className="mb-3 col-4">
          <label className="form-label" htmlFor="gtinCreateNameInput">
            Name
          </label>
          <input
            className="form-control"
            id="gtinCreateNameInput"
            name="name"
            type="text"
            autoComplete="off"
            required
            defaultValue={grai.name}
            maxLength={255}
            onChange={handleNameChange}
          />
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="gtinCreateFilterValueInput"
          >
            Filter Value
            <GraiFilterValueTooltip />
          </label>
          <Select
            name="filterValue"
            id="gtinCreateFilterValueInput"
            autoComplete="off"
            required
            onChange={({ value }) => setGrai({ ...grai, filterValue: value })}
            defaultValue={
              FilterValues.filter(
                (option) => option.value === grai.filterValue
              )[0]
            }
          />
        </div>
        <button className="btn btn-primary" type="submit">
          <FontAwesomeIcon icon={faCheck} className="me-2" />
          Save
        </button>
      </form>
    </div>
  );
};

export default GraiEdit;
