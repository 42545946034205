import axios from "axios";

import { responseRejectedInterceptor } from "../../client/client";
import { reverse } from "../../client/constants";
import { Device } from "./models";

export class Devices {
  constructor() {
    this._instance = axios.create();
    this._instance.interceptors.response.use(null, responseRejectedInterceptor);
  }

  async list() {
    const response = await this._instance.get(reverse("deviceList"));
    return response.data.map((result) => new Device(result));
  }

  async retrieve(id) {
    const response = await this._instance.get(reverse("deviceDetail", { id }));
    return new Device(response.data);
  }

  async update({
    id,
    location,
    ip,
    isDistinctAnt,
    ant1Dbm,
    ant2Dbm,
    ant3Dbm,
    ant4Dbm,
    hasReadingEnabled,
    timeout,
    antennaNote1,
    antennaNote2,
    antennaNote3,
    antennaNote4,
  }) {
    await this._instance.patch(reverse("deviceDetail", { id }), {
      location,
      ip,
      is_distinct_ant: isDistinctAnt,
      ant_1_dbm: ant1Dbm,
      ant_2_dbm: ant2Dbm,
      ant_3_dbm: ant3Dbm,
      ant_4_dbm: ant4Dbm,
      has_reading_enabled: hasReadingEnabled,
      timeout,
      antenna_note_1: antennaNote1,
      antenna_note_2: antennaNote2,
      antenna_note_3: antennaNote3,
      antenna_note_4: antennaNote4,
    });
  }
}
