import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import routes from "../../routes";
import client from "../../client";
import { FilterValues } from "../client/models";
import PageLoading from "../../components/PageLoading";
import { NotFoundError } from "../../client/errors";
import {
  IndicatorTooltip,
  BaseNumberTooltip,
  ProductRefTooltip,
  FilterValueTooltip,
} from "../../components/HelpTooltip";

const GtinEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [gtin, setGtin] = useState(undefined);

  // Event handlers
  //

  const fetchGtin = async () => {
    try {
      setGtin(await client.gtins.retrieve(id));
    } catch (error) {
      if (error instanceof NotFoundError) {
        navigate(routes.notFound);
        return;
      }

      console.error(error); // developer
      toast.error("Error"); // user
    }
  };

  useEffect(() => {
    setGtin(undefined);
    fetchGtin();
  }, [id]);

  const handleNameChange = (e) => setGtin({ ...gtin, name: e.target.value });

  const editGtin = async () => {
    try {
      await client.gtins.update(gtin.id, gtin);
      navigate(routes.gtinOverview);

      return; // resolve promise
    } catch (error) {
      console.error(error);

      throw error; // reject promise
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    toast.promise(editGtin, {
      pending: "Updating GTIN",
      success: "GTIN updated successfully",
      error: "Error while updating",
    });
  };

  // Rendering
  //

  // Display page loading (app is already loaded)
  if (gtin === undefined) {
    return <PageLoading />;
  }

  return (
    <div className="gtin-edit">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={routes.gtinOverview}>GTINs</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={routes.gtinEdit(id)}>Edit</Link>
          </li>
        </ol>
      </nav>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">Edit Gtin entry</h5>
      <form className="gtin-edit__form" onSubmit={handleFormSubmit}>
        <div className="d-flex gap-3 mb-3">
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="gtinEditIndicatorInput"
            >
              Indicator
              <IndicatorTooltip />
            </label>
            <input
              className="form-control"
              id="gtinEditIndicatorInput"
              readOnly
              value={gtin.packageIndicator}
            />
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="gtinEditBaseNumberInput"
            >
              Base Number
              <BaseNumberTooltip />
            </label>
            <input
              className="form-control"
              id="gtinEditBaseNumberInput"
              readOnly
              value={gtin.companyPrefix}
            />
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="gtinEditProductRefInput"
            >
              Product Ref
              <ProductRefTooltip />
            </label>
            <input
              className="form-control"
              id="gtinEditProductRefInput"
              value={gtin.productRef}
              readOnly
            />
          </div>
          <div>
            <label className="form-label" htmlFor="gtinEditCheckDigitInput">
              Check Digit
            </label>
            <input
              id="gtinEditCheckDigitInput"
              className="form-control"
              readOnly
              value={gtin.checkDigit}
            />
          </div>
        </div>

        <div className="mb-3 col-4">
          <label className="form-label" htmlFor="gtinEditNameInput">
            Name
          </label>
          <input
            className="form-control"
            id="gtinEditNameInput"
            name="name"
            type="text"
            autoComplete="off"
            required
            maxLength={255}
            defaultValue={gtin.name}
            onChange={handleNameChange}
          />
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="gtinEditFilterValueInput"
          >
            Filter Value
            <FilterValueTooltip />
          </label>
          <Select
            id="gtinEditFilterValueInput"
            name="filterValue"
            autoComplete="off"
            options={FilterValues}
            onChange={({ value }) => setGtin({ ...gtin, filterValue: value })}
            defaultValue={
              FilterValues.filter(
                (option) => option.value === gtin.filterValue
              )[0]
            }
            required
          />
        </div>

        <button className="btn btn-primary" type="submit">
          <FontAwesomeIcon icon={faCheck} className="me-2" />
          Save
        </button>
      </form>
    </div>
  );
};

export default GtinEdit;
