import { OverlayTrigger, Tooltip } from "react-bootstrap";
// tooltips with constant text

const TooltipTop = ({ children, text }) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      delay={{ show: 250, hide: 400 }}
      placement="right"
      overlay={<Tooltip style={{ textAlign: "start" }}>{text}</Tooltip>}
    >
      <div>{children} </div>
    </OverlayTrigger>
  );
};

export default TooltipTop;
