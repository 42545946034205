import { PaginatedListModel as PaginatedList } from "../../client/models";

class Warehouse {
  constructor(data) {
    this.id = data.id;
    this.shortcode = data.shortcode;
  }
}

export const BookingTypes = {
  EK: { value: "EK", name: "EK" },
  VK: { value: "VK", name: "VK" },
};

class ContainerAssignment {
  constructor(data) {
    this.id = data.id;
    this.created = new Date(data.created);
    this.warehouse = data.warehouse;
    this.warehouseShortcode = data.warehouse_shortcode;
    this.elementString = data.element_string;
    this.containerAlias = data.container_alias;
    this.bookingType = data.booking_type;
    this.readerMac = data.reader_mac;
    this.inHouse = data.in_house;
    this.lastContent = data.last_content;
    this.autoUpdateOnArrival = data.auto_update_on_arrival;
  }
}

class ContainerAssignmentList extends PaginatedList {
  constructor(data) {
    super(data);
    this.results = data.results.map(
      (result) => new ContainerAssignment(result)
    );
  }
}

class ItemIdAssignment {
  constructor(data) {
    this.id = data.id;
    this.created = data.created;
    this.warehouse = data.warehouse;
    this.warehouseShortcode = data.warehouse_shortcode;
    this.itemId = data.item_id;
    this.bookingType = data.booking_type;
  }
}

class ItemIdAssignmentList extends PaginatedList {
  constructor(data) {
    super(data);
    this.results = data.results.map((result) => new ItemIdAssignment(result));
  }
}

class Reader {
  constructor(data) {
    this.id = data.id;
    this.macAddress = data.mac_address;
  }
}

class Read {
  constructor(data) {
    this.id = data.id;
    this.measuredAt = new Date(data.measured_at);
    this.itemLabel = data.item_label;
    this.gs1ElementString = data.gs1_element_string;
    this.readerLocation = data.reader_location;
  }
}

class ReadList extends PaginatedList {
  constructor(data) {
    super(data);
    this.results = data.results.map((result) => new Read(result));
  }
}

export {
  ContainerAssignment as ContainerAssignmentModel,
  ContainerAssignmentList as ContainerAssignmentListModel,
  Warehouse as WarehouseModel,
  ItemIdAssignment as ItemIdAssignmentModel,
  ItemIdAssignmentList as ItemIdAssignmentListModel,
  Reader as ReaderModel,
  Read as ReadModel,
  ReadList as ReadListModel,
};
