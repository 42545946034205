import logo from "../assets/img/delta_logo_darkgrey.png";



export default function Logo() {
  return (
    <div className="d-flex align-items-center mb-3"> &emsp; &emsp;
      <img src={logo} alt="logo" width={160} height="auto" />
      <h3>&emsp; Control Panel</h3>
    </div>
  );
}
