import { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import client from "../../client";
import PageLoading from "../../components/PageLoading";
import Table from "../../components/Table";
import { classNames } from "../../utils/utils";
import routes from "../../routes";
import Pagination from "../../components/Pagination";

export default function Devices() {
  const [devices, setDevices] = useState(undefined);
  const [page, setPage] = useState(1);
  const [pageSize, ,] = useState(5);

  const columns = useMemo(
    () => [
      { name: "Network", id: "network" },
      { name: "Model", id: "model" },
      { name: "Device MAC", id: "deviceMac" },
      { name: "Note", id: "note" },
      { name: "", id: "action" },
    ],
    []
  );
  const data = useMemo(
    () =>
      devices
        ?.filter(
          (device, i) =>
            i >= (page - 1) * pageSize && i <= (page - 1) * pageSize + pageSize
        )
        .map((device) => ({
          network: (
            <div
              {...classNames([
                "status",
                device.isOnline ? "status--online" : "status--offline",
              ])}
            >
              •
            </div>
          ),
          model: device.deviceModel,
          deviceMac: device.macAddress,
          note: device.location,
          action: (
            <Link to={routes.deviceSettings(device.id)}>
              <FontAwesomeIcon
                className="icon--action hover-cursor--pointer"
                icon={faGear}
                width={24}
              />
            </Link>
          ),
        })),
    [devices, page]
  );

  const fetchDevices = async () => {
    try {
      setDevices(await client.devices.list());
    } catch (error) {
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  if (devices === undefined) {
    return <PageLoading />;
  }

  return (
    <div>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">Devices</h5>
      <div className="bg-white rounded mb-4 p-3">
        <Table columns={columns} data={data} />
        <div className="mb-3" />
        <hr className="text-black-50" size="2" />
        <Pagination
          page={page}
          resultCount={devices.length}
          pageSize={pageSize}
          setPage={(newPage) => setPage(newPage)}
        />
      </div>
    </div>
  );
}
