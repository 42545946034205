import {
  Routes,
  Route,
  Navigate,
  HashRouter,
  Outlet,
  useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import routes from "./routes";
import NotFound from "./pages/NotFound";
import ApplicationError from "./pages/ApplicationError";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Contact from "./pages/Contact";
import Register from "./pages/Register";
import { AuthProvider } from "./hooks/AuthProvider";
import { useAuth } from "./hooks/AuthProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import AppLoading from "./components/AppLoading";
import Layout from "./components/Layout";

import {
  ContainerAssignments,
  CreateContainerAssignment,
  EditContainerAssignment,
  ItemIdAssignments,
  CreateItemIdAssignment,
  EditItemIdAssignment,
  DefaultAssignments,
  CreateDefaultAssignment,
  EditDefaultAssignment
} from "./warehouse/pages";

import Gtins from "./serialization/pages/Gtins";
import GtinCreate from "./serialization/pages/GtinCreate";
import GtinEdit from "./serialization/pages/GtinEdit";
import Grais from "./serialization/pages/Grais";
import GraiCreate from "./serialization/pages/GraiCreate";
import GraiEdit from "./serialization/pages/GraiEdit";
import GtinSeries from "./serialization/pages/GtinSeries";
import GtinSeriesCreate from "./serialization/pages/GtinSeriesCreate";
import GraiSeries from "./serialization/pages/GraiSeries";
import GraiSeriesCreate from "./serialization/pages/GraiSeriesCreate";
import Unauthorized from "./pages/Unauthorized";
import { permissions } from "./client/constants";
import Devices from "./device/pages/Devices";
import Device from "./device/pages/Device";
import Summary from "./quickview/pages/Summary";
import History from "./quickview/pages/History";
import ReaderFullScan from "./quickview/pages/ReaderFullScan";

function AuthenticatedRequired({ children }) {
  const auth = useAuth();

  if (auth.user === null) {
    return <Navigate to={routes.login} replace />;
  }

  return children;
}

function NotAuthenticatedRequired({ children }) {
  const auth = useAuth();

  if (auth.user !== null) {
    return <Navigate to={routes.home} replace />;
  }

  return children;
}

function NavigateGtinEdit() {
  const { id } = useParams();
  return <Navigate to={routes.gtinEdit(id)} />;
}

function NavigateGraiEdit() {
  const { id } = useParams();
  return <Navigate to={routes.graiEdit(id)} />;
}

function PermissionRequired({ permission, children }) {
  const auth = useAuth();

  if (auth.user === null) {
    return <Navigate to={routes.login} replace />;
  }

  if (!auth.user.permissions.includes(permission)) {
    return <Unauthorized />;
  }

  return children;
}

export default function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthProvider>
        <HashRouter>
          <Routes>
            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <AppLoading>
                    <Outlet />
                  </AppLoading>
                </ErrorBoundary>
              }
            >
              <Route index element={<Navigate to={routes.profile} replace />} />
              <Route path="error" element={<ApplicationError />} />
              <Route path="404" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
              <Route
                path="login"
                element={
                  <NotAuthenticatedRequired>
                    <Login />
                  </NotAuthenticatedRequired>
                }
              />
              <Route
                path="register"
                element={
                  <NotAuthenticatedRequired>
                    <Register />
                  </NotAuthenticatedRequired>
                }
              />
              <Route
                path="contact"
                element={
                  <NotAuthenticatedRequired>
                    <Contact />
                  </NotAuthenticatedRequired>
                }
              />
              <Route
                path="profile"
                element={
                  <AuthenticatedRequired>
                    <Layout />
                  </AuthenticatedRequired>
                }
              >
                <Route index element={<Profile />} />
              </Route>
              <Route
                path="container"
                element={
                  <PermissionRequired
                    permission={permissions.canUseWarehouseService}
                  >
                    <Layout />
                  </PermissionRequired>
                }
              >
                <Route path="assignments">
                  <Route index element={<ContainerAssignments />} />
                  <Route path=":id">
                    <Route path="edit" element={<EditContainerAssignment />} />
                  </Route>
                  <Route
                    path="create"
                    element={<CreateContainerAssignment />}
                  />
                </Route>
              </Route>
              <Route
                path="itemid"
                element={
                  <PermissionRequired
                    permission={permissions.canUseWarehouseService}
                  >
                    <Layout />
                  </PermissionRequired>
                }
              >
                <Route path="assignments">
                  <Route index element={<ItemIdAssignments />} />
                  <Route path=":id">
                    <Route path="edit" element={<EditItemIdAssignment />} />
                  </Route>
                  <Route path="create" element={<CreateItemIdAssignment />} />
                </Route>
              </Route>
              <Route
                path="default"
                element={
                  <PermissionRequired
                    permission={permissions.canUseWarehouseService}
                  >
                    <Layout />
                  </PermissionRequired>
                }
              >
                <Route path="assignments">
                  <Route index element={<DefaultAssignments />} />
                  <Route path=":id">
                    <Route path="edit" element={<EditDefaultAssignment />} />
                  </Route>
                  <Route path="create" element={<CreateDefaultAssignment />} />
                </Route>
              </Route>
              <Route
                path="gtins"
                element={
                  <PermissionRequired
                    permission={permissions.canUseSerializationService}
                  >
                    <Layout />
                  </PermissionRequired>
                }
              >
                <Route index element={<Gtins />} />
                <Route path="create" element={<GtinCreate />} />
                <Route path=":id">
                  <Route index element={<NavigateGtinEdit />} />
                  <Route path="edit" element={<GtinEdit />} />
                  <Route path="series">
                    <Route index element={<GtinSeries />} />
                    <Route path="create" element={<GtinSeriesCreate />} />
                  </Route>
                </Route>
              </Route>
              <Route
                path="grais"
                element={
                  <PermissionRequired
                    permission={permissions.canUseSerializationService}
                  >
                    <Layout />
                  </PermissionRequired>
                }
              >
                <Route index element={<Grais />} />
                <Route path="create" element={<GraiCreate />} />
                <Route path=":id">
                  <Route index element={<NavigateGraiEdit />} />
                  <Route path="edit" element={<GraiEdit />} />
                  <Route path="series">
                    <Route index element={<GraiSeries />} />
                    <Route path="create" element={<GraiSeriesCreate />} />
                  </Route>
                </Route>
              </Route>
              <Route path="devices" element={<Layout />}>
                <Route index element={<Devices />} />
                <Route path=":id" element={<Device />} />
              </Route>
              <Route path="quickview" element={<Layout />}>
                <Route path="summary" element={<Summary />} />
                <Route path="history" element={<History />} />
                <Route path="fullScanMode" element={<ReaderFullScan />} />
              </Route>
            </Route>
          </Routes>
        </HashRouter>
      </AuthProvider>
    </>
  );
}
