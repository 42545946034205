import Select from "react-select";

// Page and size navigation
const Pagination = ({
  count,
  page,
  pageSize,
  pageSizeOptions,
  setPage,
  setPageAndSize,
}) => {
  // Props-based calculation
  //
  const pages = Math.ceil(count / pageSize);

  const canPrevious = page > 1;
  const canNext = page < pages;
  const startPage = page <= 3 ? 1 : page - 2;

  // Event handlers
  //
  const handlePrevious = () => {
    if (canPrevious) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (canNext) {
      setPage(page + 1);
    }
  };

  const handleItemClick = (i) => {
    if (i <= pages) {
      setPage(i);
    }
  };

  // Pagination rendering
  //
  return (
    <div className="d-flex align-items-center gap-5">
      <form className="d-flex align-items-center me-4">
        <div className="me-2">Items per page</div>
        <div style={{ width: 100 }}>
          <Select
            name="itemsPerPage"
            options={pageSizeOptions}
            value={{ value: pageSize, label: pageSize }}
            menuPlacement={"top"}
            onChange={({ value }) => setPageAndSize(1, value)}
          />
        </div>
      </form>
      <div className="me-4">Total results: {count}</div>
      <nav className="d-flex align-items-center">
        <ul className="pagination mb-0">
          <li
            className={["page-item", ...(canPrevious ? [] : ["disabled"])].join(
              " "
            )}
            onClick={handlePrevious}
          >
            <a className="page-link" role="button">
              <span>&laquo;</span>
            </a>
          </li>
          {
            // Only render 5 page navigators
            [...Array(5).keys()]
              .map((i) => i + startPage)
              .map((i) => (
                <li
                  className={[
                    "page-item",
                    ...(i === page ? ["active"] : []), // active is current
                    ...(i > pages ? ["disabled"] : []), // disabled if out of range
                  ].join(" ")}
                  key={i}
                  onClick={() => handleItemClick(i)}
                >
                  <a className="page-link" role="button">
                    {i}
                  </a>
                </li>
              ))
          }
          <li
            className={["page-item", ...(canNext ? [] : ["disabled"])].join(
              " "
            )}
            onClick={handleNext}
          >
            <a className="page-link" role="button">
              <span>&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
