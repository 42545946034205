import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";

import routes from "../routes";
import client from "../client";
import { RegisterError } from "../client/errors";
import Jumbotron from "../serialization/components/Jumbotron";
import Logo from "../components/Logo";

const Register = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
    company: "",
    email: "",
  });
  const [error, setError] = useState(undefined);

  const handleInputChange = (e) => {
    if (e.target.value) {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    } else {
      setData({
        ...data,
        [e.target.name]: "",
      });
    }
  };

  const register = async () => {
    try {
      await client.authentication.register(data);

      navigate(routes.contact, { state: { username: data.username } });

      // Promise resolved
      return;
    } catch (error) {
      if (error instanceof RegisterError) {
        setError(error);
      }

      // Display error in developer console instead of throwing into dev console or displaying in toast
      console.error(error);

      // Reject promise
      throw error;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    toast.promise(register, {
      pending: "Registering new user",
      success: "Registration successful",
      error: "Not able to register",
    });
  };

  const renderInvalidFeedbackMessages = (field) => {
    return error?.[field].length > 0
      ? error?.[field].map((message, index) => (
          <div className="invalid-feedback" key={index}>
            {message}
          </div>
        ))
      : null;
  };

  return (
    <Jumbotron>
      <Logo />
      <h3 className="mb-3">Register</h3>
      <form className="mb-3" onSubmit={handleFormSubmit}>
        <div className="mb-2">
          <label className="form-label" htmlFor="registerUsernameInput">
            Username
          </label>
          <input
            id="registerUsernameInput"
            className={
              // Conditionally add is-invalid class to the form-control
              [
                "form-control",
                ...(error?.username.length > 0 ? ["is-invalid"] : []),
              ].join(" ")
            }
            name="username"
            type="text"
            required
            autoComplete="off"
            onChange={handleInputChange}
          />
          {
            // Render all invalid-feedback messages
            renderInvalidFeedbackMessages("username")
          }
        </div>
        <div className="mb-2">
          <label className="form-label" htmlFor="registerPasswordInput">
            Password
          </label>
          <input
            id="registerPasswordInput"
            className={[
              "form-control",
              ...(error?.password.length > 0 ? ["is-invalid"] : []),
            ].join(" ")}
            name="password"
            type="password"
            required
            autoComplete="off"
            onChange={handleInputChange}
          />
          {renderInvalidFeedbackMessages("password")}
        </div>
        <div className="mb-2">
          <label className="form-label" htmlFor="registerCompanyInput">
            Company
          </label>
          <input
            id="registerCompanyInput"
            className={[
              "form-control",
              ...(error?.company.length > 0 ? ["is-invalid"] : []),
            ].join(" ")}
            name="company"
            type="text"
            autoComplete="off"
            onChange={handleInputChange}
          />
          <p className="form-text">
            Optional. This will help admin in company association.
          </p>
          {renderInvalidFeedbackMessages("company")}
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="registerEmailInput">
            Email
          </label>
          <input
            id="registerEmailInput"
            className={[
              "form-control",
              ...(error?.email.length > 0 ? ["is-invalid"] : []),
            ].join(" ")}
            name="email"
            type="email"
            autoComplete="off"
            onChange={handleInputChange}
          />
          <p className="form-text">
            Optional. Can be used by admin for contacting.
          </p>
          {renderInvalidFeedbackMessages("email")}
        </div>
        <button className="btn btn-primary" type="submit">
          Register
        </button>
      </form>
      <p>
        Already have an account? <Link to={routes.login}>Login</Link>
      </p>
    </Jumbotron>
  );
};

export default Register;
