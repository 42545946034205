import { Link } from "react-router-dom";

import routes from "../routes";
import Logo from "../components/Logo";

export default function Unauthorized() {
  return (
    <div className="jumbotron">
      <Logo />
      <h3 className="mb-3">Unauthorized</h3>
      <p>
        Sorry you don&apos;t have permission to view this page. Ask admin to
        assign necessary permissions.
      </p>
      <Link className="btn btn-primary" to={routes.profile}>
        Profile
      </Link>
    </div>
  );
}
