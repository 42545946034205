import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { faPlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PlaceHolder from "../components/PlaceHolder";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import client from "../../client";
import routes from "../../routes";
import Table from "../components/Table";
import Pagination from "../components/Pagination";
import { NotFoundError } from "../../client/errors";

const Gtins = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // State
  const [gtinList, setGtinList] = useState(undefined);
  const [sortBy, setSortBy] = useState(undefined);
  const [gtinForDelete, setSerialForDelete] = useState(undefined);

  // Derived variables
  const params = Object.fromEntries(searchParams);
  const pageSize = Number(searchParams.get("pageSize") || "10");
  const page = Number(searchParams.get("page") || "1");
  const ordering = sortBy ? sortBy[0] : undefined;

  // for data
  const handleGtinDelete = (gtin) => {
    if (gtin.sheetCount === 0) {
      setSerialForDelete(gtin);
    } else {
      toast.error(
        "Before deletion, all related series must be deleted first."
      );
    }
  };

  // Convert gtin results to data or otherwise fill with placeholders
  const data =
    // Annotate each result with edit/delete links
    gtinList?.results.map((result) => ({
      ...result,
      gtin: <div className="font-monospace">{result.gtin}</div>,
      action: (
        <div className="text-center">
          <Link to={routes.gtinEdit(result.id)}>
            <FontAwesomeIcon className="icon--action me-2" icon={faPen} />
          </Link>
          <FontAwesomeIcon
            className="icon--action"
            icon={faTrash}
            onClick={() => handleGtinDelete(result)}
          />
        </div>
      ),
    })) ||
    // Placeholder table
    Array(pageSize).fill({
      gtin: <PlaceHolder />,
      name: <PlaceHolder />,
      totalSerials: <PlaceHolder />,
      action: <PlaceHolder />,
    });

  const columns = [
    {
      Header: "GTIN",
      accessor: "gtin", // accessor is the "key" in the data
      disableSortBy: true, // sorting for strings (integer) works bad
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Total Serials",
      accessor: "totalSerials",
    },
    {
      Header: "Action",
      accessor: "action",
      disableSortBy: true,
    },
  ];

  // State manipulators
  //

  const fetchGtinList = async () => {
    try {
      setGtinList(
        await client.gtins.list({
          pageSize,
          page,
          ...(ordering && { ordering }),
        })
      );
    } catch (error) {
      if (error instanceof NotFoundError) {
        if (page !== 1) {
          setSearchParams({ ...params, page: 1 }, { replace: true });
          return;
        }
      }

      // Should not be any errors (due to the user activity), so throw error as unhandled to the console
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    setGtinList(undefined);
    fetchGtinList();
  }, [searchParams, sortBy]);

  const deleteGtin = async () => {
    try {
      await client.gtins.destroy(gtinForDelete.id);

      setSerialForDelete(undefined);
      setSearchParams({ ...params, page: 1 }, { replace: true }); // reset the page
      setGtinList(undefined);
      fetchGtinList();
      return; // resolve
    } catch (error) {
      setSerialForDelete(undefined);
      console.error(error);
      throw error; // reject deletion toast
    }
  };

  const handleRowClick = (row) => {
    // If gtinList is fetched
    if (gtinList !== undefined) {
      navigate(routes.gtinSeriesOverview(row.id));
    }
  };

  const handleTableChange = ({ sortBy }) => {
    setSortBy(sortBy);
  };

  // Rendering
  //

  return (
    <>
      <div className="gtins">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to={routes.gtinOverview}>GTINs</Link>
            </li>
          </ol>
        </nav>
        <h5 className="text-uppercase fw-bolder mt-4 mb-4">Gtin overview</h5>
        <Link className="btn btn-primary mb-4" to={routes.gtinCreate}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Create new GTIN
        </Link>
        <Table
          columns={columns}
          data={data}
          onRowClick={handleRowClick}
          rowStyle={{ cursor: "pointer" }}
          onChange={handleTableChange}
          tableFoot={
            // Render pagination only after gtins are fetched
            gtinList !== undefined && (
              <Pagination
                count={gtinList.count}
                page={page}
                pageSize={pageSize}
                pageSizeOptions={[
                  { value: 10, label: 10 },
                  { value: 20, label: 20 },
                  { value: 30, label: 30 },
                  ...([10, 20, 30].includes(pageSize)
                    ? []
                    : [{ value: pageSize, label: pageSize }]),
                ]}
                setPage={(page) =>
                  setSearchParams({ ...params, page }, { replace: true })
                }
                setPageAndSize={(newPage, newSize) =>
                  setSearchParams(
                    {
                      ...params,
                      page: newPage,
                      pageSize: newSize,
                    },
                    { replace: true }
                  )
                }
              />
            )
          }
        />
      </div>
      <Modal
        show={gtinForDelete !== undefined}
        onHide={() => setSerialForDelete(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setSerialForDelete(undefined)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(deleteGtin, {
                pending: "Deleting GTIN number",
                error: "Error while serial number",
                success: "GTIN was deleted",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Gtins;
