import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import client, {
  NotAuthenticatedError,
  NotFoundError,
  BookingTypes,
  ContainerAssignmentError,
} from "../../client";
import PageLoading from "../../components/PageLoading";
import { useAuth } from "../../hooks/AuthProvider";
import routes from "../../routes";
import {
  ElementStringTooltip,
  WarehouseShortcodeTooltip,
  ContainerAliasTooltip,
  BookingTypeTooltip,
  ReaderMacTooltip,
  InHouseTooltip,
  AutoUpdateOnArrivalTooltip,
  LastContentTooltip,
} from "../../components/Tooltips";
import {
  classNames,
  getInputInvalidFeedback,
  getInputIsInvalid,
} from "../../utils/utils";

export default function EditContainerAssignment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const [readerMacOptions, setReaderMacOptions] = useState(undefined);
  const [containerAssignment, setContainerAssignment] = useState(undefined);
  const [error, setError] = useState(undefined);

  const fetchContainerAssignment = async () => {
    try {
      setContainerAssignment(await client.containerAssignments.retrieve(id));
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      if (error instanceof NotFoundError) {
        navigate(routes.notFound, { replace: true });
        return;
      }

      console.error(error);
      toast.error("Error");
    }
  };

  const fetchReaderMacOptions = async () => {
    try {
      setReaderMacOptions(await client.readers.getOnlineReaders());
    } catch (error) {
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    fetchReaderMacOptions();
    fetchContainerAssignment();
  }, [id]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const newWarehouseId = await client.warehouses.getOrCreate({
        shortcode: containerAssignment.warehouseShortcode,
      });
      await client.containerAssignments.update({
        ...containerAssignment,
        warehouse: newWarehouseId,
      });

      navigate(routes.containerAssignments);
      return;
    } catch (error) {
      if (error instanceof ContainerAssignmentError) {
        setError(error.data);

        throw error;
      }

      console.error(error);
      throw error;
    }
  };

  if (containerAssignment === undefined) {
    return <PageLoading />;
  }

  return (
    <div>
      <h4 className="fw-bolder mt-4 mb-5">
        Edit Assignment based on Container/Tag Info
      </h4>
      <div className="row gap-5">
        <form
          className="col-5"
          onSubmit={(e) =>
            toast.promise(handleFormSubmit(e), {
              pending: "Updating container assignment",
              error: "Error while updating container assignment",
              success: "Container assignment was updated",
            })
          }
        >
          <div className="mb-3">
            <label
              className="form-label d-flex gap-2"
              htmlFor="elementStringInput"
            >
              GS1 Element String
              <ElementStringTooltip />
            </label>
            <input
              className="form-control"
              {...classNames([
                "form-control",
                getInputIsInvalid(error, "elementString") && "is-invalid",
              ])}
              id="elementStringInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              defaultValue={containerAssignment.elementString}
              onChange={(e) =>
                setContainerAssignment({
                  ...containerAssignment,
                  elementString: e.target.value,
                })
              }
            />
            {getInputInvalidFeedback(error, "elementString")}
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="warehouseShortCodeInput"
            >
              Warehouse Short Code
              <WarehouseShortcodeTooltip />
            </label>
            <input
              className="form-control"
              id="warehouseShortCodeInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              defaultValue={containerAssignment.warehouseShortcode}
              onChange={(e) =>
                setContainerAssignment({
                  ...containerAssignment,
                  warehouseShortcode: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="containerAliasInput"
            >
              Container Alias
              <ContainerAliasTooltip />
            </label>
            <input
              {...classNames([
                "form-control",
                getInputIsInvalid(error, "containerAlias") && "is-invalid",
              ])}
              id="containerAliasInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              defaultValue={containerAssignment.containerAlias}
              onChange={(e) =>
                setContainerAssignment({
                  ...containerAssignment,
                  containerAlias: e.target.value,
                })
              }
            />
            {getInputInvalidFeedback(error, "containerAlias")}
          </div>

          <div className="row ">
            <div className="mb-2 col-6">
              <label
                className="form-label d-flex gap-2"
                htmlFor="inHouseInput"
              >
                In House
                <InHouseTooltip />
              </label>
              <input
                id="inHouseInput"
                type="checkbox"
                defaultChecked={containerAssignment.inHouse}
                onChange={() =>
                  setContainerAssignment({
                    ...containerAssignment,
                    inHouse: !containerAssignment.inHouse,
                  })
                }
              />
              {getInputInvalidFeedback(error, "containerAlias")}
            </div>
            <div className="mb-2 col-6">
              <label
                className="form-label d-flex gap-2"
                htmlFor="autoUpdateOnArrivalInput"
              >
                Automatic Update on Arrival
                <AutoUpdateOnArrivalTooltip />
              </label>
              <input
                id="autoUpdateOnArrivalInput"
                type="checkbox"
                defaultChecked={containerAssignment.autoUpdateOnArrival}
                onChange={() =>
                  setContainerAssignment({
                    ...containerAssignment,
                    autoUpdateOnArrival: !containerAssignment.autoUpdateOnArrival,
                  })
                }
              />
              {getInputInvalidFeedback(error, "autoUpdateOnArrival")}
            </div>
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="lastContentInput"
            >
              Last Content
              <LastContentTooltip />
            </label>
            <input
              className="form-control"
              id="lastContentInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              defaultValue={containerAssignment.lastContent}
              onChange={(e) =>
                setContainerAssignment({
                  ...containerAssignment,
                  lastContent: e.target.value,
                })
              }
            />
          </div>

          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="bookingTypeInput"
            >
              Booking Type
              <BookingTypeTooltip />
            </label>
            <Select
              inputId="bookingTypeInput"
              menuPlacement="bottom"
              defaultValue={{
                value: containerAssignment.bookingType,
                label: containerAssignment.bookingType,
              }}
              options={[
                { value: BookingTypes.EK.value, label: BookingTypes.EK.name },
                { value: BookingTypes.VK.value, label: BookingTypes.VK.name },
              ]}
              onChange={({ value }) =>
                setContainerAssignment({
                  ...containerAssignment,
                  bookingType: value,
                })
              }
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, position: "absolute" }}
              defaultValue={containerAssignment.bookingType}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label d-flex gap-2" htmlFor="readerMacInput">
              Reader MAC
              <ReaderMacTooltip />
            </label>
            <Select
              inputId="readerMacInput"
              menuPlacement="bottom"
              closeMenuOnSelect
              isLoading={readerMacOptions === undefined}
              required
              options={readerMacOptions?.map((readerMac) => ({
                value: readerMac.macAddress,
                label: readerMac.macAddress,
              }))}
              defaultValue={{
                value: containerAssignment.readerMac,
                label: containerAssignment.readerMac,
              }}
              onChange={({ value }) =>
                setContainerAssignment({
                  ...containerAssignment,
                  readerMac: value,
                })
              }
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, position: "absolute" }}
              defaultValue={containerAssignment.readerMac}
              required
            />
          </div>
          <button className="btn btn-primary me-2" type="submit">
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Save
          </button>
          <Link className="btn btn-secondary" to={routes.containerAssignments}>
            <FontAwesomeIcon icon={faXmark} className="me-2" />
            Cancel
          </Link>
        </form>
        <div className="col-4 small fst-italic">
          <p className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-warning me-2"
              style={{ fontSize: "1.1rem" }}
            />
            <b>Priority: Primary</b>
          </p>
          <p>
            These entries control what happens to automatically and manually
            confirmed webEDI positions, where “Container Alias” is provided.
          </p>
          <p>
            This can be used to easily book specifically tagged (multi way)
            containers to a particular warehouse.
          </p>
          <p>
            Hint: As primary priority, container assignments override
            assignments based on Item IDs.
          </p>
        </div>
      </div>
    </div>
  );
}
