import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

import client, { NotAuthenticatedError } from "../../client";
import { Table, PlaceHolderTable } from "../components";
import routes from "../../routes";
import { useAuth } from "../../hooks/AuthProvider";
import { ContainerAssignmentTooltip } from "../../components/Tooltips";

export default function ContainerAssignments() {
  const auth = useAuth();

  const [containerAssignments, setContainerAssignments] = useState(undefined);
  // Store filter state outside of <Table> component because it will be unmounted on filter change
  // and the state will be lost
  const [filters, setFilters] = useState({
    globalFilter: null,
    pageSize: 10,
    pageIndex: 1,
  });
  const [selectedContainerAssignmentIds, setSelectedContainerAssignmentIds] =
    useState([]);
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);

  const fetchContainerAssignments = async () => {
    // Set loading table placeholder
    setContainerAssignments(undefined);

    const { globalFilter, pageIndex, pageSize } = filters || {};
    try {
      setContainerAssignments(
        await client.containerAssignments.list({
          search: globalFilter,
          page: pageIndex,
          pageSize,
        })
      );
    } catch (error) {
      // If refresh token have expired logout user completely
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      console.error(error);
      toast.error("Error");
    }
  };

  // Fetch assignments on initial mount and filters change
  useEffect(() => {
    fetchContainerAssignments();
  }, [filters]);

  const handleTableFiltersChange = ({ globalFilter, pageSize, pageIndex }) => {
    setFilters({ globalFilter, pageSize, pageIndex });
  };

  const handleTableRowSelectChange = (selectedRowIds) => {
    setSelectedContainerAssignmentIds(selectedRowIds);
  };

  const handleDeleteSelected = async () => {
    try {
      await client.containerAssignments.purge(selectedContainerAssignmentIds);
      fetchContainerAssignments();
      setShowDeleteSelectedModal(false);
      setSelectedContainerAssignmentIds([]);

      return;
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      console.error(error);
      throw error;
    }
  };

  // Convert state to table data/columns
  //

  const data =
    containerAssignments &&
    containerAssignments.results.map((containerAssignment) => ({
      id: containerAssignment.id, // will be used by row select
      elementString: containerAssignment.elementString,
      warehouseShortcode: containerAssignment.warehouseShortcode,
      containerAlias: containerAssignment.containerAlias,
      bookingType: containerAssignment.bookingType,
      lastContent: containerAssignment.lastContent,
      readerMac: containerAssignment.readerMac,
      inHouse: (
        <InHouseRadioButton
            id={containerAssignment.id}
            inHouse={containerAssignment.inHouse}
            onChange={() => fetchContainerAssignments()}
       />
      ),
      action: (
        <Action
          id={containerAssignment.id}
          onDelete={() => fetchContainerAssignments()}
        />
      ),
    }));

  const columns = useMemo(() => [
    { Header: "GS1 Element String", accessor: "elementString" },
    { Header: "Booking Type", accessor: "bookingType" },
    { Header: "Reader MAC", accessor: "readerMac" },
    { Header: "Container Alias", accessor: "containerAlias" },
    { Header: "Warehouse Short Code", accessor: "warehouseShortcode" },
    { Header: "Last Content", accessor: "lastContent" },
    { Header: "In-House", accessor: "inHouse" },
    { Header: "", accessor: "action" },
  ]);

  // Rendering
  //

  const renderTable = () => {
    if (containerAssignments === undefined) {
      return (
        <PlaceHolderTable
          headers={columns.map((column) => column.Header)}
          size={filters.pageSize}
        />
      );
    }

    const pageCount = Math.ceil(containerAssignments.count / filters.pageSize);

    const deleteAllButton = (
      <button
        onClick={() => {
          if (selectedContainerAssignmentIds.length > 0) {
            setShowDeleteSelectedModal(true);
          }
        }}
        className="btn btn-primary"
        disabled={selectedContainerAssignmentIds.length <= 0}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    );

    return (
      <Table
        data={data}
        columns={columns}
        controlledGlobalFilter={filters.globalFilter}
        controlledPageSize={filters.pageSize}
        controlledPageIndex={filters.pageIndex}
        controlledPageCount={pageCount}
        onChange={handleTableFiltersChange}
        onRowSelectChange={handleTableRowSelectChange}
        footer={deleteAllButton}
      />
    );
  };

  return (
    <>
      <div className="container-assignments">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="fw-bolder mt-4 mb-4">Container Assignment</h4>
          <div className="text-primary fst-italic">
            <ContainerAssignmentTooltip />
          </div>
        </div>
        <Link
          to={routes.createContainerAssignment}
          className="btn btn-primary mb-4"
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Create assignment
        </Link>
        {renderTable()}
      </div>

      <Modal
        show={showDeleteSelectedModal}
        onHide={() => setShowDeleteSelectedModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete selected container assignments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{" "}
          {selectedContainerAssignmentIds.length} assignments?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShowDeleteSelectedModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(handleDeleteSelected, {
                pending: "Deleting selected container assignments",
                error: "Error while deleting selected container assignments",
                success: "Selected container assignments were deleted",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


function InHouseRadioButton({id, inHouse, onChange}) {
  const auth = useAuth();

  const handleChangeInputRadioButton = async ({id, inHouse}) => {
    try {
      await client.containerAssignments.patch({id, inHouse});
      onChange();
      return;
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      console.error(error);
      throw error;
    }
  };
  return <div className="form-check form-switch-lg form-switch ps-2 me-2">
      <input
        className="form-check-input fs-3 ms-0 ps-0"
        type="checkbox"
        role="switch"
        defaultChecked={inHouse}
        onChange={() => {
          handleChangeInputRadioButton({id, inHouse: !inHouse})
        }}
      />
  </div>
}


function Action({ id, onDelete }) {
  const auth = useAuth();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = async () => {
    try {
      await client.containerAssignments.destroy(id);
      onDelete();
      return;
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      console.error(error);
      throw error;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Link to={routes.editContainerAssignment(id)}>
          <FontAwesomeIcon icon={faPen} color="black" />
        </Link>
        <FontAwesomeIcon
          icon={faTrash}
          className="hover-cursor--pointer"
          onClick={() => setShowDeleteModal(true)}
        />
      </div>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete container assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete container assignment?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(handleDelete, {
                pending: "Deleting container assignment",
                error: "Error while deleting container assignment",
                success: "Container assignment was deleted",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
