// Return check digit (14-th digit) based on 13 serial digits
export const getSerialCheckDigit = (thirteenDigits) => {
  // Digits
  const ds = thirteenDigits.split("").map((char) => Number(char));

  return (
    (10 -
      (((ds[0] + ds[2] + ds[4] + ds[6] + ds[8] + ds[10] + ds[12]) * 3 +
        (ds[1] + ds[3] + ds[5] + ds[7] + ds[9] + ds[11])) %
        10)) %
    10
  ).toString();
};
