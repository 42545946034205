import { classNames } from "../utils/utils";

export default function Pagination({
  resultCount,
  pageSize,
  page = 1,
  paginationSize = 5,
  setPage,
}) {
  const pageCount = Math.ceil(resultCount / pageSize);
  const canPrevious = page > 1;
  const canNext = page < pageCount;
  const middlePage = Math.ceil(paginationSize / 2);
  const startPage = page <= middlePage ? 1 : page - middlePage + 1;

  const handlePrevious = () => {
    if (canPrevious) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (canNext) {
      setPage(page + 1);
    }
  };

  const handlePageClick = (i) => {
    if (i <= pageCount) {
      setPage(i);
    }
  };

  return (
    <nav>
      <ul className="pagination">
        <li
          {...classNames(["page-item", !canPrevious && "disabled"])}
          onClick={handlePrevious}
        >
          <a className="page-link" role="button">
            <span>&laquo;</span>
          </a>
        </li>
        {[...Array(paginationSize).keys()]
          .map((i) => i + startPage)
          .map((i) => (
            <li
              key={i}
              {...classNames([
                "page-item",
                i === page && "active",
                i > pageCount && "disabled",
              ])}
              onClick={() => handlePageClick(i)}
            >
              <a className="page-link" role="button">
                {i}
              </a>
            </li>
          ))}
        <li
          {...classNames(["page-item", !canNext && "disabled"])}
          onClick={handleNext}
        >
          <a className="page-link" role="button">
            <span>&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}
