import PlaceHolder from "./PlaceHolder";

export default function PlaceHolderTable({ headers, size }) {
  return (
    <table className="table">
      <thead>
        <tr>
          {headers.map((header, headerIndex) => (
            <th key={headerIndex}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array(size).fill(
          <tr>
            {Array(headers.length).fill(
              <td>
                <PlaceHolder />
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
}
