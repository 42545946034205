import { useEffect, forwardRef, useRef } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IndeterminateCheckbox = forwardRef(function IndeterminateCheckbox(
  { indeterminate, ...rest },
  ref
) {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input
        className="form-check-input"
        type="checkbox"
        ref={resolvedRef}
        {...rest}
      />
    </>
  );
});

// Table component backed up by react-table
const Table = ({
  columns,
  data,
  onRowClick,
  rowStyle,
  tableFoot = null,
  onChange = () => {},
  rowSelect = false,
  onSelectChange = () => {},
  colSpan = 4,
}) => {
  // Table instance
  const table = useTable(
    {
      columns,
      data,
      // Options for server-side rendering
      manualSortBy: true,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
      // autoResetSelectedRows: false,
    },
    useSortBy,
    ...(rowSelect
      ? [
          useRowSelect,
          (hooks) => {
            hooks.visibleColumns.push((columns) => [
              // Selection column
              {
                id: "selection",
                // Selection header (toggle all)
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                ),
                // Selection cell (toggle row)
                Cell: ({ row }) => (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                ),
              },
              ...columns,
            ]);
          },
        ]
      : [])
  );

  // Will be applied to markup
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, selectedRowIds }, // internal table states (plugins)
  } = table;

  // console.log(pageIndex, pageSize, sortBy, filters);

  useEffect(() => {
    onChange({ sortBy });
  }, [sortBy]);

  useEffect(() => {
    onSelectChange(selectedRowIds);
  }, [selectedRowIds]);

  return (
    <table
      className="table table-hover bg-white rounded mb-4"
      {...getTableProps()}
    >
      <thead className="lh-lg">
        {headerGroups.map((group, groupIndex) => (
          <tr key={groupIndex} {...group.getHeaderGroupProps()}>
            {group.headers.map((column) => (
              <th
                key={column.id}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {
                  // Render header data
                  column.render("Header")
                }
                {column.isSorted ? (
                  <span className="ms-2">
                    {column.isSortedDesc ? (
                      <FontAwesomeIcon icon={faSortDown} />
                    ) : (
                      <FontAwesomeIcon icon={faSortUp} />
                    )}
                  </span>
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr
              className="table__row"
              key={rowIndex}
              {...row.getRowProps()}
              style={rowStyle}
              onClick={(e) => {
                // Check that the user in fact clicked on the row or data cell
                if (
                  e.target.className === "table__row" ||
                  e.target.className === "table__row__cell"
                ) {
                  onRowClick(data[rowIndex]);
                }
              }}
            >
              {row.cells.map((cell, cellIndex) => {
                return (
                  <td
                    className="table__row__cell"
                    key={cellIndex}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={colSpan}>{tableFoot}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default Table;
