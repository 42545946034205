import { removeAccessToken, removeRefreshToken, reverse } from "./constants";
import { setAccessToken, setRefreshToken } from "./constants";
import { UserModel } from "./models";
import { BadRequestError, RegisterError } from "./errors";

export class Resource {
  constructor(instance) {
    this._instance = instance;
  }
}

export class Authentication extends Resource {
  async login({ username, password }) {
    const response = await this._instance.post(reverse("login"), {
      username,
      password,
    });

    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
  }

  async user() {
    const response = await this._instance.get(reverse("user"));
    return new UserModel(response.data);
  }

  logout() {
    removeAccessToken();
    removeRefreshToken();
  }

  async update({ email }) {
    const response = await this._instance.patch(reverse("user"), { email });
    return new UserModel(response.data);
  }

  async register({ username, password, company, email }) {
    try {
      await this._instance.post(reverse("register"), {
        username,
        password,
        company,
        email,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new RegisterError(error.data);
      }
    }
  }
}
