import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../hooks/AuthProvider";
import InlineInput from "../serialization/components/InlineInput";
import routes from "../routes";

const Profile = () => {
  const auth = useAuth();

  const handleUpdateEmail = async (newEmail) => {
    try {
      await auth.updateEmail(newEmail);
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <div className="row gap-4">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to={routes.profile}>Profile</Link>
          </li>
        </ol>
      </nav>
      <div className="col">
        <h5 className="text-uppercase fw-bolder text-center mb-5">
          User profile
        </h5>
        <div className="mb-3">
          <label className="form-label" htmlFor="profileUsernameInput">
            Username
          </label>
          <input
            className="form-control"
            id="profileUsernameInput"
            readOnly
            value={auth.user.username}
          />
        </div>
        <div>
          <label className="form-label me-4" htmlFor="profileEmailInput">
            Email
          </label>
          <InlineInput
            id="profileEmailInput"
            type="email"
            className="form-control"
            defaultValue={auth.user.email}
            onSubmit={(newEmail) =>
              toast.promise(async () => await handleUpdateEmail(newEmail), {
                pending: "Updating email",
                success: "Email was updated",
                error: "Error while updating email",
              })
            }
            autoComplete="off"
          />
        </div>
      </div>
      <div className="col">
        {auth.user?.company && (
          <>
            <h5 className="text-uppercase fw-bolder text-center mb-5">
              Company details
            </h5>
            <div className="mb-3">
              <label className="form-label" htmlFor="profileCompanyInput">
                Company
              </label>
              <input
                className="form-control"
                id="profileCompanyInput"
                readOnly
                value={auth.user.company.name}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="profileStreetInput">
                Street
              </label>
              <input
                className="form-control"
                id="profileStreetInput"
                readOnly
                value={auth.user.company.street}
              />
            </div>
            <div className="mb-3 d-flex gap-3">
              <div>
                <label className="form-label" htmlFor="profilePostalCodeInput">
                  Postal Code
                </label>
                <input
                  id="profilePostalCodeInput"
                  className="form-control"
                  readOnly
                  value={auth.user.company.postalCode}
                />
              </div>
              <div>
                <label className="form-label" htmlFor="profileCityInput">
                  City
                </label>
                <input
                  id="profileCityInput"
                  className="form-control"
                  readOnly
                  value={auth.user.company.city}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="profileCountryInput">
                Country
              </label>
              <input
                className="form-control"
                id="profileCountryInput"
                readOnly
                value={auth.user.company.country}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="profileGlnInput">
                GLN
              </label>
              <input
                className="form-control"
                id="profileGlnInput"
                readOnly
                value={auth.user.company.gln}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="profilePhoneInput">
                Phone
              </label>
              <input
                className="form-control"
                id="profilePhoneInput"
                readOnly
                value={auth.user.company.phone}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="profileContactPersonInput">
                Contact Person
              </label>
              <input
                className="form-control"
                id="profileContactPersonInput"
                readOnly
                value={auth.user.company.contactPerson}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
