const authServiceBaseUrl = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
const serializationServiceBaseUrl =
  process.env.REACT_APP_SERIALIZATION_SERVICE_BASE_URL;
const warehouseServiceBaseUrl =
  process.env.REACT_APP_WAREHOUSE_SERVICE_BASE_URL;
const readerServiceBaseUrl = process.env.REACT_APP_READER_SERVICE_BASE_URL;

const urls = {
  // Authentication, registration, account management
  login: () => authServiceBaseUrl + "login/",
  register: () => authServiceBaseUrl + "register/",
  user: () => authServiceBaseUrl + "user/",
  refreshToken: () => authServiceBaseUrl + "token/refresh/",
  // Warehouse
  warehouseList: () => warehouseServiceBaseUrl + "warehouses/",
  warehouseDetail: ({ id }) => warehouseServiceBaseUrl + `warehouses/${id}`,
  // Container assignments
  containerAssignmentList: () =>
    warehouseServiceBaseUrl + "assignments/containers/",
  containerAssignmentDetail: ({ id }) =>
    warehouseServiceBaseUrl + `assignments/containers/${id}/`,
  // Item ID assignments
  itemIdAssignmentList: () => warehouseServiceBaseUrl + "assignments/itemids/",
  itemIdAssignmentDetail: ({ id }) =>
    warehouseServiceBaseUrl + `assignments/itemids/${id}/`,
  // Readers
  onlineReaderList: () => readerServiceBaseUrl + "get-online-readers",
  // GTINs
  gtinList: () => serializationServiceBaseUrl + `gtins/`,
  gtinDetail: ({ id }) => serializationServiceBaseUrl + `gtins/${id}/`,
  gtinSeriesList: () => serializationServiceBaseUrl + `sheets/gtins/`,
  gtinSeriesDetail: ({ id }) =>
    serializationServiceBaseUrl + `sheets/gtins/${id}/`,
  gtinSeriesDetailExport: ({ id }) =>
    serializationServiceBaseUrl + `sheets/gtins/${id}/export/`,
  // GRAIs
  graiList: () => serializationServiceBaseUrl + `grais/`,
  graiDetail: ({ id }) => serializationServiceBaseUrl + `grais/${id}/`,
  graiSeriesList: () => serializationServiceBaseUrl + `sheets/grais/`,
  graiSeriesDetail: ({ id }) =>
    serializationServiceBaseUrl + `sheets/grais/${id}/`,
  graiSeriesDetailExport: ({ id }) =>
    serializationServiceBaseUrl + `sheets/grais/${id}/export/`,
  // Devices
  deviceList: () => readerServiceBaseUrl + "reader/",
  deviceDetail: ({ id }) => readerServiceBaseUrl + `reader/${id}/`,

  approvedReads: () => readerServiceBaseUrl + "approved-reads",
  approvedReadCount: () => readerServiceBaseUrl + "approved-read-count",
  approvedReadCountSum: () => readerServiceBaseUrl + "approved-read-count-sum",
  readerFullScan: () => readerServiceBaseUrl + "reader-full-scan-mode"
};

export function reverse(urlName, args) {
  const url = urls[urlName](args);
  return url;
}

// Token storage service
//
const ACCESS_TOKEN_KEY_NAME = "accessToken";
const REFRESH_TOKEN_KEY_NAME = "refreshToken";

export function setAccessToken(value) {
  localStorage.setItem(ACCESS_TOKEN_KEY_NAME, value);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY_NAME);
}

export function removeAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY_NAME);
}

export function setRefreshToken(value) {
  return localStorage.setItem(REFRESH_TOKEN_KEY_NAME, value);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY_NAME);
}

export function removeRefreshToken() {
  localStorage.removeItem(REFRESH_TOKEN_KEY_NAME);
}

export const permissions = {
  canUseSerializationService: "can_use_serialization_service",
  canUseWarehouseService: "can_use_warehouse_service",
  canUseDeviceService: "can_use_device_service",
  canUseQuickViewService: "can_use_quickview_service",
};
