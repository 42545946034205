import { Link } from "react-router-dom";

import { useAuth } from "../hooks/AuthProvider";
import routes from "../routes";
import Logo from "../components/Logo";

export default function NotFound() {
  const auth = useAuth();

  return (
    <div className="jumbotron">
      <Logo />
      <h3 className="mb-3">404: Page not found</h3>
      <p>Sorry this page was not found.</p>
      {auth.user === null ? (
        <Link className="btn btn-primary" to={routes.login}>
          Login
        </Link>
      ) : (
        <Link className="btn btn-primary" to={routes.profile}>
          Profile
        </Link>
      )}
    </div>
  );
}
