import moment from "moment";
import InlineInput from "./components/InlineInput";
import { toast } from "react-toastify";
import PlaceHolder from "./components/PlaceHolder";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const saveCsvExportAsFile = (data) => {
  // Convert byte-string CSV data to blob
  const exportBlob = new Blob([data], {
    type: "text/csv",
    endings: "native",
  });

  // Save series export (convert blob to data url and click immediately)
  const link = document.createElement("a");
  link.download = "export.csv";
  link.href = URL.createObjectURL(exportBlob);
  link.click();
  URL.revokeObjectURL(exportBlob); // clear memory from export
};

export const getPageSizeOptions = (pageSize) => {
  return [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
    ...([10, 20, 30, 40, 50].includes(pageSize)
      ? []
      : [{ value: pageSize, label: pageSize }]),
  ];
};

export const getOrderingSnakeCaseName = (name) => {
  if (name === "createdAt") {
    return "created_at";
  } else if (name === "totalSerials") {
    return "total_serials";
  } else if (name === "startSerial") {
    return "start_serial";
  } else if (name === "endSerial") {
    return "end_serial";
  }

  return name;
};

export const getSeriesColumns = () => {
  return [
    {
      Header: "Created at",
      accessor: "createdAt",
    },
    {
      Header: "Note",
      accessor: "note",
      disableSortBy: true,
    },
    {
      Header: "Total Serials",
      accessor: "totalSerials",
    },
    {
      Header: "Start Serial",
      accessor: "startSerial",
    },
    {
      Header: "End Serial",
      accessor: "endSerial",
    },
    {
      Header: "Action",
      accessor: "action",
      disableSortBy: true,
    },
  ];
};

export const getSeriesData = ({
  results,
  pageSize,
  onNoteUpdate,
  onExport,
  onDelete,
}) => {
  if (results) {
    return results.map((result) => ({
      // Annotate each result with edit/delete links
      ...result,
      note: (
        <InlineInput
          className="form-control w-100"
          style={{ border: "none", padding: 3 }}
          defaultValue={result.note}
          onSubmit={(newValue) =>
            toast.promise(async () => await onNoteUpdate(result, newValue), {
              pending: "Updating series",
              success: "Series was updated",
              error: "Error while updating series",
            })
          }
        />
      ),
      createdAt: moment(result.createdAt).format("YYYY-MM-DD HH:m"),
      action: (
        <>
          <FontAwesomeIcon
            icon={faDownload}
            className="icon--action me-2"
            onClick={() => onExport(result)}
          />
          <FontAwesomeIcon
            icon={faTrash}
            className="icon--action"
            onClick={() => onDelete(result)}
          />
        </>
      ),
    }));
  } else {
    return Array(pageSize).fill({
      createdAt: <PlaceHolder />,
      note: <PlaceHolder />,
      totalSerials: <PlaceHolder />,
      startSerial: <PlaceHolder />,
      endSerial: <PlaceHolder />,
      action: <PlaceHolder />,
    });
  }
};
