import { useState } from "react";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdditionalLabels = ({ onLabel2Change, onLabel3Change }) => {
  const [shownLabels, setShownLabels] = useState({ 2: false, 3: false });

  const labelInput2 = (
    <div className="mb-2 col-4">
      <label className="form-label" htmlFor="additionalLabel2Input">
        Text Label2
      </label>
      <input
        id="additionalLabel2Input"
        className="form-control"
        type="text"
        maxLength={255}
        onChange={onLabel2Change}
        autoComplete="off"
      />
    </div>
  );

  const labelInput3 = (
    <div className="mb-2 col-4">
      <label className="form-label" htmlFor="additionalLabel3Input">
        Text Label3
      </label>
      <input
        id="additionalLabel3Input"
        className="form-control"
        type="text"
        maxLength={255}
        onChange={onLabel3Change}
        autoComplete="off"
      />
    </div>
  );

  if (shownLabels[2]) {
    if (shownLabels[3]) {
      return (
        <div>
          {labelInput2}
          {labelInput3}
        </div>
      );
    } else {
      return (
        <div>
          {labelInput2}
          <FontAwesomeIcon
            className="ms-2 mb-3"
            icon={faCirclePlus}
            role="button"
            onClick={() => setShownLabels({ ...shownLabels, 3: true })}
          />
        </div>
      );
    }
  } else {
    return (
      <div>
        <FontAwesomeIcon
          className="ms-2 mb-3"
          icon={faCirclePlus}
          role="button"
          onClick={() => setShownLabels({ ...shownLabels, 2: true })}
        />
      </div>
    );
  }
};

export default AdditionalLabels;
