import { useState } from "react";

const InlineInput = ({ defaultValue, onSubmit, className, style, ...rest }) => {
  const [newValue, setNewValue] = useState(defaultValue);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        document.activeElement.blur();
      }}
    >
      <input
        className={className}
        style={style}
        onBlur={() => {
          if (newValue !== defaultValue) {
            onSubmit(newValue);
            document.activeElement.blur();
          }
        }}
        type="text"
        defaultValue={defaultValue}
        onChange={(e) => setNewValue(e.target.value)}
        {...rest}
      />
    </form>
  );
};

export default InlineInput;
