import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import routes from "../../routes";
import client, { BadRequestError, BookingTypes } from "../../client";
import {
  BookingTypeTooltip,
  ItemIdTooltip,
  WarehouseShortcodeTooltip,
} from "../../components/Tooltips";
import {
  classNames,
  getInputIsInvalid,
  getInputInvalidFeedback,
} from "../../utils/utils";

export default function CreateItemIdAssignment() {
  const navigate = useNavigate();

  const [itemId, setItemId] = useState("");
  const [warehouseShortcode, setWarehouseShortcode] = useState("");
  const [bookingType, setBookingType] = useState("EK");
  const [error, setError] = useState(undefined);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const warehouseId = await client.warehouses.getOrCreate({
        shortcode: warehouseShortcode,
      });

      await client.itemIdAssignments.create({
        warehouse: warehouseId,
        itemId,
        bookingType,
      });

      navigate(routes.itemIdAssignments);
      return;
    } catch (error) {
      if (error instanceof BadRequestError) {
        setError(error.data);
        throw error;
      }

      console.error(error);
      throw error;
    }
  };

  return (
    <div>
      <h4 className="fw-bolder mt-4 mb-5">Create item ID assignment</h4>
      <div className="row gap-5">
        <form
          className="col-5"
          onSubmit={(e) =>
            toast.promise(handleSubmit(e), {
              pending: "Creating new item ID assignment",
              error: "Error while creating item ID assignment",
              success: "Item ID assignment was created",
            })
          }
        >
          <div className="mb-2">
            <label className="form-label d-flex gap-2" htmlFor="itemIdInput">
              Item ID
              <ItemIdTooltip />
            </label>
            <input
              className="form-control"
              {...classNames([
                "form-control",
                getInputIsInvalid(error, "itemId") && "is-invalid",
              ])}
              id="itemIdInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              onChange={(e) => setItemId(e.target.value)}
            />
            {getInputInvalidFeedback(error, "itemId")}
          </div>
          <div className="mb-2">
            <label
              className="form-label d-flex gap-2"
              htmlFor="warehouseShortCodeInput"
            >
              Warehouse Short Code
              <WarehouseShortcodeTooltip />
            </label>
            <input
              className="form-control"
              id="warehouseShortCodeInput"
              type="text"
              maxLength={255}
              autoComplete="off"
              required
              onChange={(e) => setWarehouseShortcode(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label
              className="form-label d-flex gap-2"
              htmlFor="bookingTypeInput"
            >
              Booking Type
              <BookingTypeTooltip />
            </label>
            <Select
              inputId="bookingTypeInput"
              menuPlacement="bottom"
              defaultValue={{
                value: BookingTypes.EK.value,
                label: BookingTypes.EK.name,
              }}
              options={[
                { value: BookingTypes.EK.value, label: BookingTypes.EK.name },
                { value: BookingTypes.VK.value, label: BookingTypes.VK.name },
              ]}
              onChange={({ value }) => setBookingType(value)}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, position: "absolute" }}
              defaultValue={bookingType}
              required
            />
          </div>

          <button className="btn btn-primary me-2" type="submit">
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Save
          </button>
          <Link className="btn btn-secondary" to={routes.itemIdAssignments}>
            <FontAwesomeIcon icon={faXmark} className="me-2" />
            Cancel
          </Link>
        </form>
        <div className="col-4 small fst-italic">
          <p className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-warning me-2"
              style={{ fontSize: "1.1rem" }}
            />
            <b>Priority: Secondary</b>
          </p>
          <p>
            These entries control what happens to manually confirmed webEDI
            positions, where no “Container Alias” is provided. Instead,
            warehouse assignments will be made based on the “Item ID Receiver”
            field of the webEDI.
          </p>
          <p>
            This can be used to easily book specific untagged products to a
            particular warehouse.
          </p>
          <p>
            Hint: If “Container Alias” in webEDI matches with a Container
            assignment, then Item ID assignment will be ignored.
          </p>
        </div>
      </div>
    </div>
  );
}
