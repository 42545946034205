import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

function HelpTooltip({ children, text, ...rest }) {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      delay={{ show: 250, hide: 400 }}
      placement="right"
      overlay={<Tooltip style={{ textAlign: "start" }}>{text}</Tooltip>}
      {...rest}
    >
      <div>{children} </div>
    </OverlayTrigger>
  );
}

export function ContainerAssignmentTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          <div>
            IF
            <ul>
              <li>a tag passes the assigned reader and</li>
              <li>there is a matching open delivery note at the webEDI and</li>
              <li>there is an outstanding order in the ERP</li>
            </ul>
            or IF
            <ul>
              <li>the &quot;Confirm&quot; Button in webEDI is pressed and</li>
              <li>there is an outstanding order in the ERP</li>
            </ul>
            THEN an ERP booking will be created automatically.
          </div>
        </>
      }
      placement="left"
    >
      How does it work?
      <FontAwesomeIcon icon={faCircleQuestion} className="ms-2" />
    </HelpTooltip>
  );
}

export function ElementStringTooltip() {
  return (
    <HelpTooltip text={<>RFID/Barcode Tag Identifier.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function WarehouseShortcodeTooltip() {
  return (
    <HelpTooltip text={<>Warehouse identifier used in the ERP.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function ContainerAliasTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          Identifier printed on containers and/or labels. Used for simplified
          human read and write interactions.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function BookingTypeTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          Determines whether bookings of this entry should be related to a
          purchase (EK) or sale (VK). EK generally results in incoming stocks.
          VK generally results in outgoing stocks.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function InHouseTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          If checked, the container currently resides in-house.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function LastContentTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          Info field indicating the article number of most recent container movement.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function AutoUpdateOnArrivalTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          If checked, `In-House` and `Last Content` will
          automatically be updated once the container arrives.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}




export function ReaderMacTooltip() {
  return (
    <HelpTooltip text={<>Device identifier where a tag shall be read.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function ItemIdTooltip() {
  return (
    <HelpTooltip text={<>Identifier of a product in your ERP.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function ConnectManuallyTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          If checked, reader connection will be tried to be established by a
          specific local IP. If unchecked, the local network will be scanned and
          a connection will be established automatically.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function IPAddressTooltip() {
  return (
    <HelpTooltip
      text={<>Specific network IP to try connection establishment to reader.</>}
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function NoteTooltip() {
  return (
    <HelpTooltip
      text={<>Additional Information like location or device usage.</>}
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function DistinguishAntennasTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          If checked, the reader will generate individual data for its separate
          antennas. Therefore, each antenna will behave like an independent
          reader.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function ReadPowerTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          Read Power influences the relative reading range. Overall range is
          also influenced by antenna type, tag size and surrounding environment.
          <br />
          Additional antenna note can be used to describe orientation or type of
          an antenna. It is especially useful to describe the exact location of
          an antenna if “Distinguish Antenna” is checked.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function TagIgnoreTimeTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          Minimum gap time between two approved reads of identical tags. If the
          same tag is read before that time has passed, the read will not be
          approved but ejected. This feature is used to prevent unwanted
          overlaps at automated processes. It is especially important if no
          filtering is applied afterwards.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}

export function EnableReadingTooltip() {
  return (
    <HelpTooltip
      text={
        <>
          If checked, the reader will start the reading process or autostart as
          soon as it is online. If set to False while reading, the reader will
          stop reading.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </HelpTooltip>
  );
}
