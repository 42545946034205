// TODO: change from array to ...arguments
export function classNames(arr) {
  return {
    className: arr.filter(Boolean).join(" "),
  };
}

export function getInputIsInvalid(errorData, inputName) {
  return errorData?.[inputName]?.length > 0;
}

export function getInputInvalidFeedback(errorData, inputName) {
  if (getInputIsInvalid(errorData, inputName)) {
    return errorData?.[inputName]?.map((errorMessage, index) => (
      <div className="invalid-feedback" key={index}>
        {errorMessage}
      </div>
    ));
  }

  return null;
}
