import { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import client from "../../client";
import { FilterValues } from "../client/models";
import routes from "../../routes";
import { getSerialCheckDigit } from "../serials";
import { FormError } from "../../client/errors";
import {
  IndicatorTooltip,
  BaseNumberTooltip,
  ProductRefTooltip,
  FilterValueTooltip,
} from "../../components/HelpTooltip";

const GtinCreate = () => {
  const navigate = useNavigate();

  const [gtinData, setGtinData] = useState({
    packageIndicator: "",
    companyPrefix: "",
    productRef: "",
    checkDigit: "-",
    name: "",
    filterValue: 0,
  });
  const [submitted, setSubmitted] = useState(false);

  // Computations
  //

  const companyPrefixMaxLength = 12 - gtinData.productRef.length;
  const productRefMaxLength = 12 - gtinData.companyPrefix.length;
  const gtin13 =
    gtinData.packageIndicator + gtinData.companyPrefix + gtinData.productRef;

  // Calculate check digit based on packageIndicator, companyPrefix and productRef.
  if (gtin13.length === 13) {
    gtinData.checkDigit = getSerialCheckDigit(gtin13);
  } else {
    gtinData.checkDigit = "";
  }

  // Calculated errors (on every update)
  const errors = { companyPrefix: [] };
  if (gtinData.companyPrefix.length < 6) {
    errors.companyPrefix.push("Must be at least 6 digits.");
  }
  if (gtinData.companyPrefix.length + gtinData.productRef.length !== 12) {
    errors.companyPrefix.push("Together with product ref must be 12 digits.");
  }

  // Event handlers
  //

  const handleInputChange = (e) => {
    if (e.target.value) {
      setGtinData({ ...gtinData, [e.target.name]: e.target.value });
    } else {
      setGtinData({ ...gtinData, [e.target.name]: "" });
    }
  };

  const createGtin = async () => {
    try {
      await client.gtins.create(gtinData);

      navigate(routes.gtinOverview);

      return; // resolved
    } catch (error) {
      if (error instanceof FormError) {
        error.nonFieldErrors.map((nfr) => toast.error(nfr));
        throw error; // reject create
      }

      console.error(error);
      throw error; // reject toast
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // If there are any errors display them but don't send request
    if (errors.companyPrefix.length > 0) {
      setSubmitted(true);
      return;
    }

    // Create new GTIN
    await toast.promise(createGtin, {
      pending: "Creating new GTIN",
      success: "GTIN was created",
      error: "Error while creating GTIN",
    });
  };

  // Rendering
  //

  const isInvalid = (field) => {
    // Only is invalid after submit
    if (!submitted) {
      return false;
    }

    // console.log("Field in errors: ", field in errors);
    return errors[field]?.length > 0;
  };

  const renderErrors = (field) => {
    // If the form was submitted display field array client-side
    if (submitted) {
      return errors?.[field]?.map((error, index) => (
        <div className="invalid-feedback" key={index}>
          {error}
        </div>
      ));
    }
  };

  return (
    <div className="gtin-create">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={routes.gtinOverview}>GTINs</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={routes.gtinCreate}>Create</Link>
          </li>
        </ol>
      </nav>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">Gtin create</h5>
      <form onSubmit={handleFormSubmit}>
        <div className="d-flex gap-3 mb-3">
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="gtinCreateIndicatorInput"
            >
              Indicator
              <IndicatorTooltip />
            </label>
            <input
              className="form-control"
              id="gtinCreateIndicatorInput"
              name="packageIndicator"
              type="text"
              autoFocus
              pattern="^\d+$"
              required
              maxLength={1}
              autoComplete="off"
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="gtinCreateBaseNumberInput"
            >
              Base Number
              <BaseNumberTooltip />
            </label>
            <input
              className={[
                "form-control",
                ...(isInvalid("companyPrefix") ? ["is-invalid"] : []),
              ].join(" ")}
              id="gtinCreateBaseNumberInput"
              name="companyPrefix"
              type="text"
              pattern="^\d+$"
              required
              autoComplete="off"
              maxLength={companyPrefixMaxLength}
              onChange={handleInputChange}
            />
            {renderErrors("companyPrefix")}
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="gtinCreateProductRefInput"
            >
              Product Ref
              <ProductRefTooltip />
            </label>
            <input
              className={[
                "form-control",
                ...(isInvalid("productRef") ? ["is-invalid"] : []),
              ].join(" ")}
              id="gtinCreateProductRefInput"
              name="productRef"
              type="text"
              pattern="^\d+$"
              autoComplete="off"
              maxLength={productRefMaxLength > 6 ? 6 : productRefMaxLength}
              onChange={handleInputChange}
            />
            {renderErrors("productRef")}
          </div>
          <div>
            <label className="form-label" htmlFor="gtinCreateCheckDigitInput">
              Check Digit
            </label>
            <input
              id="gtinCreateCheckDigitInput"
              className="form-control"
              name="checkDigit"
              type="text"
              readOnly
              value={gtinData.checkDigit}
            />
          </div>
        </div>
        <div className="mb-3 col-4">
          <label className="form-label" htmlFor="gtinCreateNameInput">
            Name
          </label>
          <input
            className="form-control"
            id="gtinCreateNameInput"
            name="name"
            type="text"
            autoComplete="off"
            required
            maxLength={255}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="gtinCreateFilterValueInput"
          >
            Filter Value
            <FilterValueTooltip />
          </label>
          <Select
            name="filterValue"
            id="gtinCreateFilterValueInput"
            autoComplete="off"
            options={FilterValues}
            onChange={({ value }) =>
              setGtinData({ ...gtinData, filterValue: value })
            }
            defaultValue={{ value: 0, label: "All other" }}
            required
          />
        </div>
        <button className="btn btn-primary" type="submit">
          <FontAwesomeIcon icon={faCheck} className="me-2" />
          Create
        </button>
      </form>
    </div>
  );
};

export default GtinCreate;
