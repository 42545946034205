import { useState, useEffect, useRef, useMemo } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeOff } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import client from "../../client";
import PageLoading from "../../components/PageLoading";
import beep from "../../assets/audio/friendly_shortbeep.wav";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import IconToggleButton from "../../components/IconToggleButton";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import PageSizeSelect from "../../components/PageSizeSelect";

export default function History() {
  const [devices, setDevices] = useState(undefined);
  const [reads, setReads] = useState(undefined);
  const [timeDelta, setTimeDelta] = useState("10m");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isAudioMuted, setIsAudioMuted] = useState(
    !!localStorage.getItem("isAudioMuted")
  );
  let { current: filterChanged } = useRef(false);
  let { current: intervalId } = useRef(undefined);
  const beepAudio = useMemo(() => new Audio(beep), []);
  const deviceOptions = useMemo(
    () =>
      devices?.map((device) => ({
        value: device.id,
        label: `${device.macAddress} (${device.location})`,
      })),
    [devices]
  );
  const timeFrameOptions = useMemo(
    () => [
      { value: "10m", label: "Last 10 min" },
      { value: "24h", label: "Last 24 h" },
      { value: "7d", label: "Last 7 d" },
    ],
    []
  );
  const columns = useMemo(
    () => [
      { name: "Time", id: "time" },
      { name: "Name", id: "name" },
      { name: "GS1 Element String", id: "gs1ElementString" },
      { name: "Device Location", id: "deviceLocation" },
    ],
    []
  );
  const data = useMemo(
    () =>
      reads?.results.map((read) => ({
        time: (
          <div className="white-space-nowrap text-start">
            {moment(read.measuredAt).format("YYYY-MM-DD H:mm:ss")}
          </div>
        ),
        name: (
          <div className="white-space-nowrap text-start">{read.itemLabel}</div>
        ),
        gs1ElementString: (
          <div className="white-space-nowrap text-start">
            {read.gs1ElementString}
          </div>
        ),
        deviceLocation: (
          <div
            className="single-line-ellipsis hover-white-space-normal"
            style={{ maxWidth: 180 }}
          >
            {read.readerLocation}
          </div>
        ),
      })),
    [reads]
  );

  useEffect(() => {
    fetchDevices();
  }, []);

  useEffect(() => {
    setReads(undefined);
    fetchReads();

    clearInterval(intervalId);
    intervalId = setInterval(async () => {
      await fetchReads();
    }, 3000); // TODO: set to 3000 or faster
    return () => {
      clearInterval(intervalId);
    };
  }, [page, pageSize, selectedDevices, timeDelta]);

  useUpdateEffect(() => {
    filterChanged = true;
    setPage(1);
    setPageSize(10);
  }, [selectedDevices, timeDelta]);

  const fetchDevices = async () => {
    setDevices(await client.devices.list());
  };

  const fetchReads = async () => {
    const newReads = await client.readers.getApprovedReads({
      timeDelta,
      readerIds: selectedDevices.map((device) => device.value),
      page,
      pageSize,
    });

    setReads((reads) => {
      setIsAudioMuted((isAudioMuted) => {
        if (
          !isAudioMuted &&
          !filterChanged &&
          reads !== undefined &&
          reads.results?.at(0)?.id !== newReads.results?.at(0)?.id
        ) {
          beepAudio.play();
        }
        return isAudioMuted;
      });
      return newReads;
    });

    filterChanged = false;
  };

  if (devices === undefined || reads === undefined) {
    return <PageLoading />;
  }

  return (
    <div>
      <h4 className="fw-bolder mt-4 mb-4">History</h4>

      <div className="mb-3">
        <IconToggleButton
          on={!isAudioMuted}
          onToggle={() => {
            // Sync local storage
            localStorage.setItem("isAudioMuted", !isAudioMuted ? "1" : "");
            // Sync state
            setIsAudioMuted(!isAudioMuted);
          }}
          iconOn={<FontAwesomeIcon icon={faVolumeHigh} />}
          iconOff={<FontAwesomeIcon icon={faVolumeOff} />}
        />
      </div>
      <div className="d-flex gap-2 mb-4">
        <form style={{ width: "75%" }}>
          <label className="form-label" htmlFor="devicesSelect">
            Devices
          </label>

          <Select
            inputId="devicesSelect"
            menuPlacement="bottom"
            defaultValue={selectedDevices}
            options={deviceOptions}
            isMulti
            // theme={reactSelectTheme}
            // styles={reactSelectStyles}
            onChange={(options) => setSelectedDevices(options)}
          />
        </form>
        <form style={{ width: "25%" }}>
          <label className="form-label" htmlFor="timeFrameInput">
            Timeframe
          </label>
          <Select
            inputId="timeFrameInput"
            options={timeFrameOptions}
            defaultValue={timeFrameOptions.find(
              (timeFrameOption) => timeFrameOption.value === timeDelta
            )}
            onChange={({ value }) => setTimeDelta(value)}
            menuPlacement="bottom"
          />
        </form>
      </div>

      <div className="bg-white rounded p-3" style={{ marginBottom: 100 }}>
        <div className="mb-3">
          <Table columns={columns} data={data} />
        </div>
        <hr className="text-black-50" size="2" />
        <div className="d-flex gap-2">
          <Pagination
            page={page}
            resultCount={reads.count}
            pageSize={pageSize}
            setPage={(newPage) => setPage(newPage)}
          />
          <PageSizeSelect
            pageSizeOptions={[5, 10, 15]}
            defaultValue={10}
            onChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </div>
      </div>
    </div>
  );
}
