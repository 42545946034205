export class Device {
  constructor(data) {
    this.id = data.id;
    this.isOnline = data.is_online;
    this.macAddress = data.mac_address;
    this.location = data.location;
    this.deviceModel = data.device_model;
    this.ip = data.ip;
    this.timeout = data.timeout;
    this.hasReadingEnabled = data.has_reading_enabled;
    this.isDistinctAnt = data.is_distinct_ant;
    this.ant1Dbm = data.ant_1_dbm;
    this.ant2Dbm = data.ant_2_dbm;
    this.ant3Dbm = data.ant_3_dbm;
    this.ant4Dbm = data.ant_4_dbm;
    this.isAnt1Online = data.is_ant_1_online;
    this.isAnt2Online = data.is_ant_2_online;
    this.isAnt3Online = data.is_ant_3_online;
    this.isAnt4Online = data.is_ant_4_online;
    this.antennaNote1 = data.antenna_note_1;
    this.antennaNote2 = data.antenna_note_2;
    this.antennaNote3 = data.antenna_note_3;
    this.antennaNote4 = data.antenna_note_4;
  }
}
