import { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import client from "../../client";
import routes from "../../routes";
import { getSerialCheckDigit } from "../serials";
import { FormError } from "../../client/errors";
import {
  BaseNumberTooltip,
  AssetTypeTooltip,
  GraiFilterValueTooltip,
} from "../../components/HelpTooltip";

const GraiCreate = () => {
  const navigate = useNavigate();

  const [graiData, setGraiData] = useState({
    companyPrefix: "",
    assetType: "", // productRef
    checkDigit: "-",
    name: "",
    filterValue: 0,
  });
  const [submitted, setSubmitted] = useState(false);

  // Computations
  //

  const companyPrefixMaxLength = 12 - graiData.assetType.length;
  const assetTypeMaxLength = 12 - graiData.companyPrefix.length;
  const grai13 = "0" + graiData.companyPrefix + graiData.assetType;

  if (grai13.length === 13) {
    graiData.checkDigit = getSerialCheckDigit(grai13);
  } else {
    graiData.checkDigit = "";
  }

  // Calculated errors (on every update)
  const errors = { companyPrefix: [] };
  if (graiData.companyPrefix.length < 6) {
    errors.companyPrefix.push("Must be at least 6 digits.");
  }
  if (graiData.companyPrefix.length + graiData.assetType.length !== 12) {
    errors.companyPrefix.push("Together with asset type must be 12 digits.");
  }

  // Event handlers
  //

  const handleInputChange = (e) => {
    if (e.target.value) {
      setGraiData({ ...graiData, [e.target.name]: e.target.value });
    } else {
      setGraiData({ ...graiData, [e.target.name]: "" });
    }
  };

  const createGrai = async () => {
    try {
      await client.grais.create(graiData);

      navigate(routes.graiOverview);

      return;
    } catch (error) {
      if (error instanceof FormError) {
        error.nonFieldErrors.map((nfr) => toast.error(nfr));
        throw error;
      }

      console.error(error);
      throw error; // reject toast
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (errors.companyPrefix.length > 0) {
      setSubmitted(true);
      return;
    }

    await toast.promise(createGrai, {
      pending: "Creating new GRAI",
      success: "GRAI was created",
      error: "Error while creating GRAI",
    });
  };

  // Rendering
  //

  const isInvalid = (field) => {
    if (!submitted) {
      return false;
    }

    return errors[field]?.length > 0;
  };

  const renderErrors = (field) => {
    if (submitted) {
      return errors?.[field]?.map((error, index) => (
        <div className="invalid-feedback" key={index}>
          {error}
        </div>
      ));
    }
  };

  return (
    <div className="grai-create">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={routes.graiOverview}>GRAIs</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={routes.graiCreate}>Create</Link>
          </li>
        </ol>
      </nav>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">Grai create</h5>
      <form onSubmit={handleFormSubmit}>
        <div className="d-flex gap-3 mb-3">
          <div>
            <input
              className="form-control"
              style={{ marginTop: 30 }}
              type="text"
              disabled
              value={0}
            />
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="graiCreateBaseNumberInput"
            >
              Base Number
              <BaseNumberTooltip />
            </label>
            <input
              id="graiCreateBaseNumberInput"
              className={[
                "form-control",
                ...(isInvalid("companyPrefix") ? ["is-invalid"] : []),
              ].join(" ")}
              name="companyPrefix"
              type="text"
              pattern="^\d+$"
              required
              autoComplete="off"
              maxLength={companyPrefixMaxLength}
              onChange={handleInputChange}
            />
            {renderErrors("companyPrefix")}
          </div>
          <div>
            <label
              className="form-label d-flex gap-2"
              htmlFor="graiCreateAssetTypeInput"
            >
              Asset type
              <AssetTypeTooltip />
            </label>
            <input
              id="graiCreateAssetTypeInput"
              className={[
                "form-control",
                ...(isInvalid("assetType") ? ["is-invalid"] : []),
              ].join(" ")}
              name="assetType"
              type="text"
              pattern="^\d+$"
              autoComplete="off"
              maxLength={assetTypeMaxLength > 6 ? 6 : assetTypeMaxLength}
              onChange={handleInputChange}
            />
            {renderErrors("assetType")}
          </div>
          <div>
            <label className="form-label" htmlFor="graiCreateCheckDigitInput">
              Check Digit
            </label>
            <input
              id="graiCreateCheckDigitInput"
              className="form-control"
              name="checkDigit"
              type="text"
              readOnly
              value={graiData.checkDigit}
            />
          </div>
        </div>
        <div className="mb-3 col-4">
          <label className="form-label" htmlFor="graiCreateNameInput">
            Name
          </label>
          <input
            id="graiCreateNameInput"
            className="form-control"
            name="name"
            type="text"
            autoComplete="off"
            required
            maxLength={255}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="graiCreateFilterValueInput"
          >
            Filter Value
            <GraiFilterValueTooltip />
          </label>
          <Select
            id="graiCreateFilterValueInput"
            name="filterValue"
            autoComplete="off"
            onChange={({ value }) =>
              setGraiData({ ...graiData, filterValue: value })
            }
            defaultValue={{ value: 0, label: "All other" }}
            disabled
            required
          />
        </div>
        <button className="btn btn-primary" type="submit">
          <FontAwesomeIcon icon={faCheck} className="me-2" />
          Create
        </button>
      </form>
    </div>
  );
};

export default GraiCreate;
