import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tooltip from "../serialization/components/Tooltip";

export const IndicatorTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          <div>
            Packaging Indicator assigned by you (e.g. Display, Carton, Pallet).
            0 - no meaning. 1 to 8 - package Level (self-assigned).
          </div>
          <div>
            Hint: If planning to use barcodes that support less than 14 symbols
            (e.g. EAN-13), it may be best to choose 0 and create a new gtin for
            each package level.
          </div>
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const BaseNumberTooltip = () => {
  return (
    <Tooltip text={<>Your company&apos;s base number assigned by GS1.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const ProductRefTooltip = () => {
  return (
    <Tooltip text={<>Choose a product reference on your own behalf.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const FilterValueTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          Specifies a category used to encode the Electronic Product Code (EPC)
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const AssetTypeTooltip = () => {
  return (
    <Tooltip text={<>Choose an asset type on your own behalf.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const GraiFilterValueTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          {
            `
            Specifies a category used to encode the Electronic Product Code (EPC). \n
            0 - all others \n
            1 - point of sale\n
            2 - case\n
            4 - inner pack\n
            5 - uni load\n
            7 - component\n
            As of now, filter values other than 0 are reserved for advanced users.
            `
          }
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const NoteTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          Additional Information like series usage or comments. This field
          will not be part of the csv export.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const TotalSerialsTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          Total amount of new serials to be generated. By default, this will
          generate a list of unused serials starting from above the highest used
          serial. This is guaranteed to not create duplicates.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const LabelSerialsTooltip = () => {
  return (
    <Tooltip text={<>Define a Label that can be printed onto the tag.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const StartSerialTooltip = () => {
  return (
    <Tooltip text={<>Fix start value for the serial range.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const EndSerialTooltip = () => {
  return (
    <Tooltip text={<>Fix end value for the serial range.</>}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const RowsPerSerialTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          Create multiple (consecutive) rows in the csv export file. Useful to create
          consecutive copies of a print. Example: Entering “2” results in the
          sequence: 1,1 - 2,2 - 3,3 - ...
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const AliasPrefixTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          Fixed phrase prior an incrementing value of an alias. Alias can be
          used for alternative labelling and additional enumeration. Optional.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const AliasStartIncrementTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          Define the self incrementing start value of an alias. Alias can be
          used for alternative labelling and additional enumeration. Optional.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};

export const AliasSuffixTooltip = () => {
  return (
    <Tooltip
      text={
        <>
          Fixed phrase subsequent of an incrementing value of an alias. Alias
          can be used for alternative labelling and additional enumeration.
          Optional.
        </>
      }
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  );
};
