import Select from "react-select";

export default function PageSizeSelect({
  id = "pageSizeInput",
  pageSizeOptions,
  defaultValue,
  onChange,
}) {
  return (
    <Select
      inputId={id}
      menuPlacement="bottom"
      options={pageSizeOptions.map((pageSize) => ({
        value: pageSize,
        label: pageSize,
      }))}
      defaultValue={{
        value: defaultValue,
        label: defaultValue,
      }}
      onChange={({ value }) => onChange(value)}
      maxMenuHeight={100}
    />
  );
}
