import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import client from "../../client";
import routes from "../../routes";
import PageLoading from "../../components/PageLoading";
import AdditionalLabels from "../components/AdditionalLabels";
import { NotFoundError } from "../../client/errors";
import {
  NoteTooltip,
  TotalSerialsTooltip,
  LabelSerialsTooltip,
  StartSerialTooltip,
  EndSerialTooltip,
  RowsPerSerialTooltip,
} from "../../components/HelpTooltip";

const GtinSimpleModeCreate = ({ gtin, gtinLastSeries, onCreate }) => {
  const [note, setNote] = useState("");
  const [totalNewSerials, setTotalNewSerials] = useState(undefined);
  const [label1, setLabel1] = useState("");
  const [label2, setLabel2] = useState("");
  const [label3, setLabel3] = useState("");

  // Calculate
  const lastSeriesEndSerial = gtinLastSeries ? gtinLastSeries.endSerial : 0;
  const startSerial = lastSeriesEndSerial + 1;
  const endSerial = lastSeriesEndSerial + 1 + Number(totalNewSerials);

  const createSeries = async () => {
    try {
      await client.gtinSeries.create({
        gtin: gtin.id,
        startSerial,
        endSerial,
        note,
        label1,
        label2,
        label3,
        outputRowFactor: 1,
        // series filter value will be default to gtin.filterValue
      });

      // Navigate to series list
      onCreate();

      return; // resolve promise
    } catch (error) {
      // Display error to the console (handle)
      console.error(error);

      throw error; // reject promise
    }
  };

  // Event handlers
  const handleNoteChange = (e) => setNote(e.target.value);
  const handleTotalNewSerialsChange = (e) => setTotalNewSerials(e.target.value);
  const handleLabel1Change = (e) => setLabel1(e.target.value);
  const handleLabel2Change = (e) => setLabel2(e.target.value);
  const handleLabel3Change = (e) => setLabel3(e.target.value);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    toast.promise(createSeries, {
      pending: "Creating a series",
      error: "Error while series creation",
      success: "Series was successfully created",
    });
  };

  // Rendering
  //

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-3 col-4">
        <label className="form-label" htmlFor="gtinSeriesCreateGtinInput">
          GTIN
        </label>
        <input
          className="form-control col-3"
          id="gtinSeriesCreateGtinInput"
          readOnly
          value={gtin?.gtin}
        />
      </div>
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="gtinSeriesCreateNoteInput"
        >
          Note
          <NoteTooltip />
        </label>
        <input
          id="gtinSeriesCreateNoteInput"
          className="form-control"
          name="note"
          type="text"
          maxLength={255}
          onChange={handleNoteChange}
          autoComplete="off"
        />
      </div>
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="gtinSeriesCreateTotalNewSerialsInput"
        >
          Total New Serials
          <TotalSerialsTooltip />
        </label>
        <input
          id="gtinSeriesCreateTotalNewSerialsInput"
          className="form-control"
          name="totalNewSerials"
          type="number"
          min={1}
          max={50_000}
          onChange={handleTotalNewSerialsChange}
          required
        />
        {Number(totalNewSerials) > 0 && (
          <div className="form-text">
            Start serial: {startSerial}. End serial: {endSerial}.
          </div>
        )}
      </div>
      <div className="mb-2 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="gtinSeriesCreateLabel1Input"
        >
          Text Label
          <LabelSerialsTooltip />
        </label>
        <input
          id="gtinSeriesCreateLabel1Input"
          className="form-control"
          name="label1"
          maxLength={255}
          onChange={handleLabel1Change}
          required
          autoComplete="off"
        />
      </div>
      <AdditionalLabels
        onLabel2Change={handleLabel2Change}
        onLabel3Change={handleLabel3Change}
      />
      <button className="btn btn-primary" type="submit">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create
      </button>
    </form>
  );
};

const GtinAdvanceModeCreate = ({ gtin, gtinLastSeries, onCreate }) => {
  const [note, setNote] = useState("");
  const lastSeriesEndSerial = gtinLastSeries ? gtinLastSeries.endSerial : 0;
  const [startSerial, setStartSerial] = useState(lastSeriesEndSerial + 1);
  const [endSerial, setEndSerial] = useState(lastSeriesEndSerial + 2);
  const [label1, setLabel1] = useState("");
  const [label2, setLabel2] = useState("");
  const [label3, setLabel3] = useState("");
  const [rowsPerSerial, setRowsPerSerial] = useState(1);
  const [submitted, setSubmitted] = useState(false);

  // Calculate possible errors
  //
  const errors = { endSerial: [] };

  if (endSerial <= startSerial) {
    errors.endSerial.push("End serial must be greater than starting serial.");
  }
  // console.log(errors);

  // Event handlers
  const createSeries = async () => {
    try {
      await client.gtinSeries.create({
        gtin: gtin.id,
        startSerial,
        endSerial,
        note,
        label1,
        label2,
        label3,
        outputRowFactor: rowsPerSerial,
      });
      onCreate();
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleNoteChange = (e) => setNote(e.target.value);
  const handleLabel1Change = (e) => setLabel1(e.target.value);
  const handleLabel2Change = (e) => setLabel2(e.target.value);
  const handleLabel3Change = (e) => setLabel3(e.target.value);
  const handleStartSerialChange = (e) => setStartSerial(e.target.value);
  const handleEndSerialChange = (e) => setEndSerial(e.target.value);
  const handleRowsPerSerialChange = (e) => setRowsPerSerial(e.target.value);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (errors.endSerial.length > 0) {
      setSubmitted(true);
      return;
    }

    toast.promise(createSeries, {
      pending: "Creating a series",
      error: "Error while series creation",
      success: "Series was successfully created",
    });
  };

  // Rendering
  //

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mb-3 col-4">
        <label className="form-label" htmlFor="gtinSeriesCreateGtinInput">
          GTIN
        </label>
        <input
          className="form-control col-3"
          id="gtinSeriesCreateGtinInput"
          readOnly
          value={gtin?.gtin}
        />
      </div>
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="gtinSeriesCreateNoteInput"
        >
          Note
          <NoteTooltip />
        </label>
        <input
          id="gtinSeriesCreateNoteInput"
          className="form-control"
          name="note"
          type="text"
          maxLength={255}
          onChange={handleNoteChange}
          autoComplete="off"
        />
      </div>
      <div className="d-flex gap-3">
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="gtinSeriesCreateStartSerialInput"
          >
            Start Serial
            <StartSerialTooltip />
          </label>
          <input
            id="gtinSeriesCreateStartSerialInput"
            className="form-control"
            name="startSerial"
            type="number"
            min={0}
            defaultValue={lastSeriesEndSerial + 1}
            required
            onChange={handleStartSerialChange}
          />
        </div>
        <div className="mb-3 col-4">
          <label
            className="form-label d-flex gap-2"
            htmlFor="gtinSeriesCreateEndSerialInput"
          >
            End Serial
            <EndSerialTooltip />
          </label>
          <input
            id="gtinSeriesCreateEndSerialInput"
            className={[
              "form-control",
              ...(errors.endSerial.length > 0 && submitted
                ? ["is-invalid"]
                : [""]),
            ].join(" ")}
            name="endSerial"
            type="number"
            min={1}
            defaultValue={lastSeriesEndSerial + 2}
            required
            onChange={handleEndSerialChange}
          />
          {submitted &&
            errors.endSerial.map((error, index) => (
              <div className="invalid-feedback" key={index}>
                {error}
              </div>
            ))}
          {endSerial > startSerial && (
            <div className="form-text">
              Total serials: {endSerial - startSerial + 1}.
            </div>
          )}
        </div>
      </div>
      <div className="mb-2 col-4">
        <label className="form-label" htmlFor="gtinSeriesCreateLabel1Input">
          Text Label
        </label>
        <input
          id="gtinSeriesCreateLabel1Input"
          className="form-control"
          name="label1"
          maxLength={255}
          onChange={handleLabel1Change}
          required
          autoComplete="off"
        />
      </div>
      <AdditionalLabels
        onLabel2Change={handleLabel2Change}
        onLabel3Change={handleLabel3Change}
      />
      <div className="mb-3 col-4">
        <label
          className="form-label d-flex gap-2"
          htmlFor="gtinSeriesCreateRowsPerSerialInput"
        >
          Rows per Serial
          <RowsPerSerialTooltip />
        </label>
        <input
          id="gtinSeriesCreateRowsPerSerialInput"
          className="form-control"
          name="rowsPerSerial"
          type="number"
          required
          min={1}
          max={30}
          defaultValue={1}
          onChange={handleRowsPerSerialChange}
        />
      </div>
      <button className="btn btn-primary" type="submit">
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create
      </button>
    </form>
  );
};

const GtinSeriesCreate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isSimpleMode, setIsSimpleMode] = useState(true);
  const [gtin, setGtin] = useState(undefined);
  const [gtinLastSeries, setGtinLastSeries] = useState(undefined);

  const fetchGtin = async () => {
    try {
      setGtin(await client.gtins.retrieve(id));
    } catch (error) {
      if (error instanceof NotFoundError) {
        navigate(routes.notFound, { replace: true });
        return;
      }

      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    setGtin(undefined);
    fetchGtin();
  }, [id]);

  const fetchGtinLastSeries = async () => {
    try {
      const gtinSeries = await client.gtinSeries.list({ gtin: id });
      if (gtinSeries.count > 0) {
        // Set the last series filtered descending by `end_serial`
        setGtinLastSeries(gtinSeries.results[0]);
      } else {
        // If not series are created for GTIN => set to null
        setGtinLastSeries(null);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    if (gtin !== undefined) {
      fetchGtinLastSeries();
    }
  }, [gtin]);

  // Rendering
  //

  if (gtin === undefined) {
    return <PageLoading />;
  }

  const handleSeriesCreate = () => {
    navigate(routes.gtinSeriesOverview(id));
  };

  return (
    <div>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={routes.gtinOverview}>GTINs</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={routes.gtinSeriesOverview(id)}>Series</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={routes.gtinSeriesOverview(id)}>Create</Link>
          </li>
        </ol>
      </nav>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">
        Create new GTIN Print Series
      </h5>
      <div className="d-flex align-items-center gap-2 mb-3">
        <div className={[...(isSimpleMode ? ["fw-bold"] : [])].join(" ")}>
          Simple
        </div>
        <div className="form-check form-switch-lg form-switch ps-2 me-2">
          <input
            className="form-check-input fs-3 ms-0 ps-0"
            type="checkbox"
            role="switch"
            id="uiSimpleAdvanceSwitch"
            onChange={() => setIsSimpleMode(!isSimpleMode)}
          />
        </div>
        <div className={[...(!isSimpleMode ? ["fw-bold"] : [])].join(" ")}>
          Advanced
        </div>
      </div>
      {isSimpleMode ? (
        <GtinSimpleModeCreate
          gtin={gtin}
          gtinLastSeries={gtinLastSeries}
          onCreate={handleSeriesCreate}
        />
      ) : (
        <GtinAdvanceModeCreate
          gtin={gtin}
          gtinLastSeries={gtinLastSeries}
          onCreate={handleSeriesCreate}
        />
      )}
    </div>
  );
};

export default GtinSeriesCreate;
