import { useState, useEffect, useRef, useMemo } from "react";
import Select from "react-select";
import moment from "moment";

import client from "../../client";
import PageLoading from "../../components/PageLoading";
import Table from "../../components/Table";

export default function ReaderFullScan() {
  const [devices, setDevices] = useState(undefined);
  const [reads, setReads] = useState(undefined);
  const [selectedDevices, setSelectedDevices] = useState([]);
  let { current: intervalId } = useRef(undefined);
  const deviceOptions = useMemo(
    () =>
      devices?.map((device) => ({
        value: device.id,
        label: `${device.macAddress} (${device.location})`,
      })),
    [devices]
  );

  const columns = useMemo(
    () => [
      { name: "epc_hex", id: "epc_hex" },
      { name: "reader_mac_address", id: "reader_mac_address" },
      { name: "reader_ant", id: "reader_ant" },
      { name: "last_rssi", id: "last_rssi" },
      { name: "last_measured_at", id: "last_measured_at" },
      { name: "count", id: "count" },
    ],
    []
  );
  const data = useMemo(
    () =>
      reads?.map((read) => ({
        epc_hex: (
          <div className="white-space-nowrap text-start">
            {read.epc_hex}
          </div>
        ),
        reader_mac_address: (
          <div className="white-space-nowrap text-start">
            {read.reader_mac_address}
          </div>
        ),

        reader_ant: (
          <div className="white-space-nowrap text-start">
            {read.reader_ant}
          </div>
        ),
        last_rssi: (
          <div className="white-space-nowrap text-start">
            {read.last_rssi}
          </div>
        ),
        last_measured_at: (
          <div className="white-space-nowrap text-start">
            {moment(read.last_measured_at).format("YYYY-MM-DD H:mm:ss")}
          </div>
        ),
        count: (
          <div className="white-space-nowrap text-start">
            {read.count}
          </div>
        ),
      })),
    [reads]
  );

  useEffect(() => {
    fetchDevices();
  }, []);

  useEffect(() => {
    setReads(undefined);
    fetchReads();

    clearInterval(intervalId);
    intervalId = setInterval(async () => {
      await fetchReads();
    }, 3000); // TODO: set to 3000 or faster
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedDevices]);

  const fetchDevices = async () => {
    setDevices(await client.devices.list());
  };

  const fetchReads = async () => {
    const newReads = await client.readers.getFullScan({
      readerIds: selectedDevices.map((device) => device.value),
    });

    setReads((reads) => {
      reads !== undefined;
      newReads.results?.at(0)?.id;
      return newReads;
    });

  };

  if (devices === undefined || reads === undefined) {
    return <PageLoading />;
  }

  return (
    <div>
      <h4 className="fw-bolder mt-4 mb-4">FullScanMode</h4>

      <div className="d-flex gap-2 mb-4">
        <form style={{ width: "75%" }}>
          <label className="form-label" htmlFor="devicesSelect">
            Devices
          </label>

          <Select
            inputId="devicesSelect"
            menuPlacement="bottom"
            defaultValue={selectedDevices}
            options={deviceOptions}
            isMulti
            // theme={reactSelectTheme}
            // styles={reactSelectStyles}
            onChange={(options) => setSelectedDevices(options)}
          />
        </form>
      </div>

      <div className="bg-white rounded p-3" style={{ marginBottom: 100 }}>
        <div className="mb-3">
          <Table columns={columns} data={data} />
        </div>
        <hr className="text-black-50" size="2" />
      </div>
    </div>
  );
}
