import { useState, useEffect, useRef } from "react";
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import client from "../../client";
import Table from "../components/Table";
import Pagination from "../components/Pagination";
import routes from "../../routes";
import { GtinSeriesError, NotFoundError } from "../../client/errors";
import {
  saveCsvExportAsFile,
  getPageSizeOptions,
  getOrderingSnakeCaseName,
  getSeriesColumns,
  getSeriesData,
} from "../series";

const GtinSeries = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [gtin, setGtin] = useState(undefined);
  const [gtinSeriesList, setGtinSeriesList] = useState(undefined);
  const [seriesForConfirmDelete, setSeriesForConfirmDelete] = useState(undefined);
  const [seriesForConfirmExport, setSeriesForConfirmExport] = useState(undefined);
  const [sortBy, setSortBy] = useState(undefined);
  const selectedSeries = useRef();
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);

  // Derived variables
  const params = Object.fromEntries(searchParams);
  const pageSize = Number(searchParams.get("pageSize") || "10");
  const page = Number(searchParams.get("page") || "1");

  // Event, state handling
  //

  const fetchGtin = async () => {
    try {
      setGtin(await client.gtins.retrieve(id));
    } catch (error) {
      if (error instanceof NotFoundError) {
        navigate(routes.notFound);
      }
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    fetchGtin();
  }, [id]);

  const fetchGtinSeries = async () => {
    try {
      let orderingName = undefined;
      if (sortBy !== undefined && sortBy[0]) {
        // Sort by first only
        orderingName = getOrderingSnakeCaseName(sortBy[0].id);
      }

      setGtinSeriesList(
        await client.gtinSeries.list({
          page,
          pageSize,
          gtin: id,
          ...(orderingName && {
            ordering: `${sortBy[0].desc ? "-" : ""}${orderingName}`,
          }),
        })
      );
    } catch (error) {
      if (error instanceof GtinSeriesError) {
        if (error.gtin.length > 0) {
          navigate(routes.notFound, { replace: true });
          return;
        }
      }
      if (error instanceof NotFoundError) {
        if (page !== 1) {
          setSearchParams({ ...params, page: 1 }, { replace: true });
          return;
        }
      }
      console.error(error);
      toast.error("Error");
    }
  };

  // Refetch data on gtin id, page or sorting change
  useEffect(() => {
    // Refresh all series
    setSearchParams({ ...params, page: 1 }, { replace: true });
    setGtinSeriesList(undefined);
    fetchGtinSeries();
  }, [id, searchParams, sortBy]);

  const deleteSeries = async () => {
    try {
      await client.gtinSeries.destroy(seriesForConfirmDelete.id);

      setGtinSeriesList(undefined);
      fetchGtinSeries();
      return;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setSeriesForConfirmDelete(undefined);
    }
  };

  const updateSeriesNote = async (series, newValue) => {
    try {
      const newSeries = await client.gtinSeries.update(series.id, {
        note: newValue,
      });

      // Substitute updated series
      setGtinSeriesList({
        ...gtinSeriesList,
        results: [
          ...gtinSeriesList.results.map((s) =>
            s.id === newSeries.id ? newSeries : s
          ),
        ],
      });
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const exportSeries = async () => {
    const confirmExportSeriesId = seriesForConfirmExport.id;

    // Close confirm export modal immediately
    setSeriesForConfirmExport(undefined);

    try {
      const data = await client.gtinSeries.export(confirmExportSeriesId);
      saveCsvExportAsFile(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleTableChange = ({ sortBy }) => setSortBy(sortBy);

  const deleteSelectedSeries = async () => {
    setShowDeleteSelectedModal(false);

    try {
      // Delete all selected series by ids
      await client.gtinSeries.purge(
        Object.keys(selectedSeries.current).map((index) => data[index].id)
      );

      // Refresh all series
      setSearchParams({ ...params, page: 1 }, { replace: true });
      setGtinSeriesList(undefined);
      fetchGtinSeries();

      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // Convert gtin results to data or otherwise fill with placeholders
  const data = getSeriesData({
    results: gtinSeriesList?.results,
    pageSize,
    onNoteUpdate: updateSeriesNote,
    onExport: setSeriesForConfirmExport,
    onDelete: setSeriesForConfirmDelete,
  });

  const handleDeleteSelectedClick = () => {
    if (selectedSeries?.current) {
      if (Object.keys(selectedSeries.current).length > 0) {
        setShowDeleteSelectedModal(true);
      }
    }
  };

  const handleDeleteSelectedCancel = () => {
    selectedSeries.current = undefined;
    setShowDeleteSelectedModal(false);
  };

  const columns = getSeriesColumns();

  // Rendering
  //

  return (
    <div className="gtin-series">
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={routes.gtinOverview}>GTINs</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={routes.gtinSeriesOverview(id)}>Series</Link>
          </li>
        </ol>
      </nav>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">
        Gtin series overview
      </h5>
      <div className="mb-3 col-3">
        <label className="form-label" htmlFor="gtinSeriesGtinInput">
          GTIN
        </label>
        <input
          className="form-control col-3"
          id="gtinSeriesGtinInput"
          readOnly
          value={gtin?.gtin}
        />
      </div>
      <div className="mb-4 col-3">
        <label className="form-label" htmlFor="gtinSeriesNameInput">
          Name
        </label>
        <input
          id="gtinSeriesNameInput"
          className="form-control"
          readOnly
          value={gtin?.name}
        />
      </div>
      <Link className="btn btn-primary mb-4" to={routes.gtinSeriesCreate(id)}>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create new GTIN series
      </Link>
      <Table
        columns={columns}
        data={data}
        onRowClick={() => {}}
        onChange={handleTableChange}
        rowSelect={true}
        onSelectChange={(newSelectedSeries) => {
          // Reset selected rows only when modal is not shown
          if (!showDeleteSelectedModal) {
            // Save selected series in ref to prevent rerendering
            selectedSeries.current = newSelectedSeries;
          }
        }}
        colSpan={7}
        tableFoot={
          gtinSeriesList !== undefined && (
            <div className="d-flex align-items-center">
              {/* Delete selected rows */}
              <FontAwesomeIcon
                icon={faTrash}
                className="icon--action fs-5 me-4"
                onClick={handleDeleteSelectedClick}
              />
              <Pagination
                count={gtinSeriesList.count}
                page={page}
                pageSize={pageSize}
                pageSizeOptions={getPageSizeOptions(pageSize)}
                setPage={(newPage) =>
                  setSearchParams(
                    { ...params, page: newPage },
                    { replace: true }
                  )
                }
                setPageSize={(newPageSize) =>
                  setSearchParams(
                    { ...params, pageSize: newPageSize },
                    { replace: true }
                  )
                }
                setPageAndSize={(newPage, newPageSize) =>
                  setSearchParams(
                    {
                      ...params,
                      page: newPage,
                      pageSize: newPageSize,
                    },
                    { replace: true }
                  )
                }
              />
            </div>
          )
        }
      />

      {/* Confirm delete modal */}
      <Modal
        show={seriesForConfirmDelete !== undefined}
        onHide={() => setSeriesForConfirmDelete(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You may lose track of your SGTINs. Are you sure you want to delete
          this entry?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setSeriesForConfirmDelete(undefined)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(deleteSeries, {
                pending: "Deleting GTIN series",
                error: "Error while deleting series",
                success: "Series was deleted",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      {/* Confirm export modal */}
      <Modal
        show={seriesForConfirmExport !== undefined}
        onHide={() => setSeriesForConfirmExport(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will generate a full CSV export for all serials in the series with
          provided rows per serial number.
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setSeriesForConfirmExport(undefined)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(exportSeries, {
                pending: "Exporting series",
                error: "Error while series exporting",
                success: "Series was exported successfully",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      {/* Confirm delete selected rows */}
      <Modal show={showDeleteSelectedModal} onHide={handleDeleteSelectedCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Delete selected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You may lose track of your SGTINs. Are you sure you want to delete all
          selected entries?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleDeleteSelectedCancel}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(deleteSelectedSeries, {
                pending: "Deleting series",
                error: "Error while deleting series",
                success: "Series were deleted",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GtinSeries;
