import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../hooks/AuthProvider";
import routes from "../routes";
import { BadRequestError } from "../client/errors";
import Logo from "../components/Logo";

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isInvalid, setIsInvalid] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const login = async () => {
    try {
      await auth.login({ username, password });
      navigate(routes.profile, { replace: true });

      // Promise resolved
      return;
    } catch (error) {
      if (error instanceof BadRequestError) {
        // Mark form invalid
        setIsInvalid(true);

        // Display error toast
        // toast.error(".");
      }

      // Promise rejected
      console.error(error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    toast.promise(login, {
      pending: "Logging-in with provided credentials",
      success: "Login successfully",
      error: "Unable to login with provided credentials",
    });
  };

  return (
    <div className="jumbotron pb-5">
      <Logo />
      <h3 className="mb-4">Login</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <label className="form-label" htmlFor="usernameInput">
            Username
          </label>
          <input
            id="usernameInput"
            className={[
              "form-control",
              ...(isInvalid ? ["is-invalid"] : []),
            ].join(" ")}
            name="username"
            type="text"
            required
            autoComplete="off"
            onChange={handleUsernameChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="passwordInput">
            Password
          </label>
          <input
            id="passwordInput"
            className={[
              "form-control",
              ...(isInvalid ? ["is-invalid"] : []),
            ].join(" ")}
            name="password"
            type="password"
            required
            autoComplete="off"
            onChange={handlePasswordChange}
          />
        </div>
        <button className="btn btn-primary" type="submit">
          Login
        </button>
      </form>
    </div>
  );
}
