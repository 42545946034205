import { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import PageLoading from "../../components/PageLoading";
import client, { NotFoundError } from "../../client";
import routes from "../../routes";
import { toast } from "react-toastify";
import { classNames } from "../../utils/utils";
import {
  ConnectManuallyTooltip,
  DistinguishAntennasTooltip,
  EnableReadingTooltip,
  IPAddressTooltip,
  NoteTooltip,
  ReadPowerTooltip,
  TagIgnoreTimeTooltip,
} from "../../components/Tooltips";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

// Device settings page (DEU4)
export default function Device() {
  const params = useParams();
  const id = parseInt(params.id);
  const navigate = useNavigate();

  const [device, setDevice] = useState(undefined);
  const readPowerChoices = useMemo(
    () =>
      [...Array(34).keys()].map((readPower) => ({
        value: readPower,
        label: readPower,
      })),
    []
  );

  const fetchDevice = async () => {
    try {
      const deviceData = await client.devices.retrieve(id);

      const ignoreTimeDay = parseInt(deviceData.timeout / (60 * 60 * 24));
      deviceData.timeout -= ignoreTimeDay * 24 * 60 * 60;
      const ignoreTimeHour = parseInt(deviceData.timeout / (60 * 60));
      deviceData.timeout -= ignoreTimeHour * 60 * 60;
      const ignoreTimeMin = parseInt(deviceData.timeout / 60);
      deviceData.timeout -= ignoreTimeMin * 60;
      const ignoreTimeSec = deviceData.timeout;

      setDevice({
        ...deviceData,
        connectManually: !!deviceData.ip,
        ignoreTimeDay,
        ignoreTimeHour,
        ignoreTimeMin,
        ignoreTimeSec,
      });
    } catch (error) {
      if (error instanceof NotFoundError) {
        navigate(routes.notFound);
        return;
      }

      console.error(error);
      throw error; // toast.error("Error")
    }
  };

  useEffect(() => {
    fetchDevice();
  }, [id]);

  if (device === undefined) {
    return <PageLoading />;
  }

  const handleConnectManuallyToggle = () => {
    if (device.ip) {
      // Turn off connect manually
      setDevice({ ...device, connectManually: false, ip: "" });
    } else {
      // Turn on connect manually
      setDevice({ ...device, connectManually: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const timeout =
        device.ignoreTimeDay * 24 * 60 * 60 +
        device.ignoreTimeHour * 60 * 60 +
        device.ignoreTimeMin * 60 +
        device.ignoreTimeSec;
      await client.devices.update({ ...device, timeout });

      navigate(routes.devices);
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <div>
      <h5 className="text-uppercase fw-bolder mt-4 mb-4">Device settings</h5>

      <form
        onSubmit={(e) =>
          toast.promise(handleSubmit(e), {
            pending: "Updating device settings",
            error: "Error while updating device settings",
            success: "Successfully updated device settings",
          })
        }
      >
        <div className="mb-2">
          <label className="form-label" htmlFor="modelInput">
            Model
          </label>
          <input
            id="modelInput"
            className="form-control"
            value={device.deviceModel}
            readOnly
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="macAddressInput">
            Device MAC
          </label>
          <input
            id="macAddressInput"
            className="form-control"
            value={device.macAddress}
            readOnly
          />
        </div>
        <div className="form-check mb-1">
          <input
            id="connectManuallyInput"
            className="form-check-input"
            type="checkbox"
            defaultChecked={device.connectManually}
            onChange={handleConnectManuallyToggle} // toggle event
          />
          <label
            className="form-label d-flex gap-2"
            htmlFor="connectManuallyInput"
          >
            Connect Manually
            <ConnectManuallyTooltip />
          </label>
        </div>
        <div className="mb-2">
          <label className="form-label d-flex gap-2" htmlFor="ipAddressInput">
            IP
            <IPAddressTooltip />
          </label>
          <input
            id="ipAddressInput"
            className="form-control"
            type="text"
            value={device.ip}
            disabled={!device.connectManually}
            autoComplete="off"
            maxLength={255}
            onChange={(e) => setDevice({ ...device, ip: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label className="form-label d-flex gap-2" htmlFor="noteInput">
            Note
            <NoteTooltip />
          </label>
          <input
            id="noteInput"
            className="form-control"
            type="text"
            autoComplete="off"
            maxLength={255}
            defaultValue={device.location}
            onChange={(e) => setDevice({ ...device, location: e.target.value })}
          />
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            defaultChecked={device.isDistinctAnt}
            onChange={() =>
              // Toggle distinct antenna
              setDevice({ ...device, isDistinctAnt: !device.isDistinctAnt })
            }
          />
          <label className="form-label d-flex gap-2">
            Distinguish Antennas <DistinguishAntennasTooltip />
          </label>
        </div>
        <div>
          <label className="form-label d-flex gap-2">
            Read Power & Note (per antenna) <ReadPowerTooltip />
          </label>
          <div className="gx-3 mb-3">
            <div className="d-flex gap-3 align-items-end mb-2">
              <div className="col" style={{ maxWidth: 100 }}>
                <label
                  className="form-label small d-flex gap-2"
                  htmlFor="antenna1Input"
                >
                  Antenna 1 <Status value={device.isAnt1Online} />
                </label>
                <Select
                  inputId="antenna1Input"
                  menuPlacement="bottom"
                  defaultValue={{
                    value: device.ant1Dbm,
                    label: device.ant1Dbm,
                  }}
                  options={readPowerChoices}
                  onChange={({ value }) =>
                    setDevice({ ...device, ant1Dbm: value })
                  }
                  maxMenuHeight={100}
                />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  maxLength={255}
                  defaultValue={device.antennaNote1}
                  onChange={(e) =>
                    setDevice({ ...device, antennaNote1: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="d-flex gap-3 align-items-end mb-2">
              <div className="col" style={{ maxWidth: 100 }}>
                <label
                  className="form-label small d-flex gap-2"
                  htmlFor="antenna2Input"
                >
                  Antenna 2 <Status value={device.isAnt2Online} />
                </label>
                <Select
                  inputId="antenna2Input"
                  menuPlacement="bottom"
                  defaultValue={{
                    value: device.ant2Dbm,
                    label: device.ant2Dbm,
                  }}
                  options={readPowerChoices}
                  onChange={({ value }) =>
                    setDevice({ ...device, ant2Dbm: value })
                  }
                  maxMenuHeight={100}
                />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  maxLength={255}
                  defaultValue={device.antennaNote2}
                  onChange={(e) =>
                    setDevice({ ...device, antennaNote2: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="d-flex gap-3 align-items-end mb-2">
              <div className="col" style={{ maxWidth: 100 }}>
                <label
                  className="form-label small d-flex gap-2"
                  htmlFor="antenna3Input"
                >
                  Antenna 3 <Status value={device.isAnt3Online} />
                </label>
                <Select
                  inputId="antenna3Input"
                  menuPlacement="bottom"
                  defaultValue={{
                    value: device.ant3Dbm,
                    label: device.ant3Dbm,
                  }}
                  options={readPowerChoices}
                  onChange={({ value }) =>
                    setDevice({ ...device, ant3Dbm: value })
                  }
                  maxMenuHeight={100}
                />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  maxLength={255}
                  defaultValue={device.antennaNote3}
                  onChange={(e) =>
                    setDevice({ ...device, antennaNote3: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="d-flex gap-3 align-items-end mb-2">
              <div className="col" style={{ maxWidth: 100 }}>
                <label
                  className="form-label small d-flex gap-2"
                  htmlFor="antenna4Input"
                >
                  Antenna 4 <Status value={device.isAnt4Online} />
                </label>
                <Select
                  inputId="antenna4Input"
                  menuPlacement="bottom"
                  defaultValue={{
                    value: device.ant4Dbm,
                    label: device.ant4Dbm,
                  }}
                  options={readPowerChoices}
                  onChange={({ value }) =>
                    setDevice({ ...device, ant4Dbm: value })
                  }
                  maxMenuHeight={100}
                />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  type="text"
                  autoComplete="off"
                  maxLength={255}
                  defaultValue={device.antennaNote4}
                  onChange={(e) =>
                    setDevice({ ...device, antennaNote4: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center gap-5">
          <div>
            <label className="form-label d-flex gap-2">
              Tag Ignore Time
              <TagIgnoreTimeTooltip />
            </label>
            <div className="row row-cols-4 gx-2 mb-3" style={{ maxWidth: 500 }}>
              <div className="col">
                <label
                  className="form-label small"
                  htmlFor="ignoreTimeSecInput"
                >
                  s
                </label>
                <Select
                  inputId="ignoreTimeSecInput"
                  menuPlacement="bottom"
                  defaultValue={{
                    value: device.ignoreTimeSec,
                    label: device.ignoreTimeSec,
                  }}
                  options={[...Array(60).keys()].map((i) => ({
                    value: i,
                    label: i,
                  }))}
                  onChange={({ value }) =>
                    setDevice({ ...device, ignoreTimeSec: value })
                  }
                  maxMenuHeight={100}
                />
              </div>
              <div className="col">
                <label
                  className="form-label small"
                  htmlFor="ignoreTimeMinInput"
                >
                  min
                </label>
                <Select
                  inputId="ignoreTimeMinInput"
                  menuPlacement="bottom"
                  defaultValue={{
                    value: device.ignoreTimeMin,
                    label: device.ignoreTimeMin,
                  }}
                  options={[...Array(60).keys()].map((i) => ({
                    value: i,
                    label: i,
                  }))}
                  onChange={({ value }) =>
                    setDevice({ ...device, ignoreTimeMin: value })
                  }
                  maxMenuHeight={100}
                />
              </div>
              <div className="col">
                <label
                  className="form-label small"
                  htmlFor="ignoreTimeHourInput"
                >
                  h
                </label>
                <Select
                  inputId="ignoreTimeHourInput"
                  menuPlacement="bottom"
                  defaultValue={{
                    value: device.ignoreTimeHour,
                    label: device.ignoreTimeHour,
                  }}
                  options={[...Array(24).keys()].map((i) => ({
                    value: i,
                    label: i,
                  }))}
                  onChange={({ value }) =>
                    setDevice({ ...device, ignoreTimeHour: value })
                  }
                  maxMenuHeight={100}
                />
              </div>
              <div className="col">
                <label
                  className="form-label small"
                  htmlFor="ignoreTimeDayInput"
                >
                  d
                </label>
                <input
                  id="ignoreTimeDayInput"
                  className="form-control"
                  type="number"
                  defaultValue={device.ignoreTimeDay}
                  onChange={(e) =>
                    setDevice({
                      ...device,
                      ignoreTimeDay: parseInt(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </div>
          {device.ignoreTimeHour === 0 && device.ignoreTimeDay === 0 && (
            <div className="col-4 small fst-italic">
              <p>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="text-warning me-2"
                  style={{ fontSize: "1.1rem" }}
                />
                Attention: Low Tag ignore times may result in unwanted overlaps.
                Make sure to quickly clear the reading area and/or apply
                additional filtering.
              </p>
            </div>
          )}
        </div>
        <div className="form-check mb-3">
          <input
            id="enableReadingInput"
            className="form-check-input"
            type="checkbox"
            defaultChecked={device.hasReadingEnabled}
            onChange={() =>
              setDevice({
                ...device,
                hasReadingEnabled: !device.hasReadingEnabled,
              })
            }
          />
          <label
            className="form-label d-flex gap-2"
            htmlFor="enableReadingInput"
          >
            Enable Reading
            <EnableReadingTooltip />
          </label>
        </div>
        <div>
          <button type="submit" className="btn btn-primary me-2">
            Save
          </button>
          <Link to={routes.devices} className="btn btn-secondary">
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
}

function Status({ value }) {
  return (
    <div
      {...classNames([
        "status",
        value === true && "status--online",
        value === false && "status--offline",
      ])}
    >
      •
    </div>
  );
}
