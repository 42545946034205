import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSatelliteDish,
  faListOl,
  faWarehouse,
  faMagnifyingGlassChart,
} from "@fortawesome/free-solid-svg-icons";

import routes from "../routes";
import logo from "../assets/img/delta_logo_sign_white.png";
import { useAuth } from "../hooks/AuthProvider";
import { permissions } from "../client/constants";

const Sidebar = () => {
  const auth = useAuth();

  return (
    <div className="col-3 bg-primary text-white px-4">
      <div className="d-flex align-items-center mb-4 mt-4">
        <img src={logo} alt="logo" width={65} height="auto" />
        <h4 className="fw-bolder"> &nbsp; &nbsp; Control Panel</h4>
      </div>
      <hr size="3" />
      {auth.user.permissions.includes(
        permissions.canUseSerializationService
      ) && (
        <div className="accordion mb-1" id="gs1-accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-formats"
              >
                <FontAwesomeIcon className="me-2" icon={faListOl} />
                Serial Manager
              </button>
            </div>
            <div
              id="collapse-formats"
              className="accordion-collapse collapse show"
              data-bs-parent="#gs1-accordion"
            >
              <nav className="accordion-body">
                <div className="mb-2">
                  <NavLink
                    to={routes.gtinOverview}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    GTINs
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to={routes.graiOverview}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    GRAIs
                  </NavLink>
                </div>
              </nav>
            </div>
          </div>
        </div>
      )}
      {auth.user.permissions.includes(permissions.canUseWarehouseService) && (
        <div className="accordion mb-1" id="warehouse-accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-warehouse-according"
              >
                <FontAwesomeIcon className="me-2" icon={faWarehouse} />
                Warehouse Assignment
              </button>
            </div>
            <div
              id="collapse-warehouse-according"
              className="accordion-collapse collapse"
              data-bs-parent="#warehouse-accordion"
            >
              <nav className="accordion-body">
                <div className="mb-2">
                  <NavLink
                    to={routes.containerAssignments}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    Container
                  </NavLink>
                </div>
                <div className="mb-2">
                  <NavLink
                    to={routes.itemIdAssignments}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    Item ID
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to={routes.defaultAssignments}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    Default
                  </NavLink>
                </div>
              </nav>
            </div>
          </div>
        </div>
      )}
      {auth.user.permissions.includes(permissions.canUseDeviceService) && (
        <div className="accordion mb-1" id="device-accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-device-according"
              >
                <FontAwesomeIcon className="me-2" icon={faSatelliteDish} />
                Device Manager
              </button>
            </div>
            <div
              id="collapse-device-according"
              className="accordion-collapse collapse"
              data-bs-parent="#device-accordion"
            >
              <nav className="accordion-body">
                <div className="mb-2">
                  <NavLink
                    to={routes.devices}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    Devices
                  </NavLink>
                </div>
              </nav>
            </div>
          </div>
        </div>
      )}
      {auth.user.permissions.includes(permissions.canUseQuickViewService) && (
        <div className="accordion mb-1" id="quickview-accordion">
          <div className="accordion-item">
            <div className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-quickview"
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={faMagnifyingGlassChart}
                />
                QuickView
              </button>
            </div>
            <div
              id="collapse-quickview"
              className="accordion-collapse collapse show"
              data-bs-parent="#quickview-accordion"
            >
              <nav className="accordion-body">
                <div className="mb-2">
                  <NavLink
                    to={routes.summary}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    Summary
                  </NavLink>
                </div>
                <div className="mb-2">
                  <NavLink
                    to={routes.history}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    History
                  </NavLink>
                </div>
                <div className="mb-2">
                  <NavLink
                    to={routes.fullScanMode}
                    className={({ isActive }) =>
                      isActive
                        ? "text-decoration-none text-primary"
                        : "text-decoration-none text-black"
                    }
                  >
                    FullScanMode
                  </NavLink>
                </div>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
