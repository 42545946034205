const routes = {
  home: "/",
  notFound: "/404",
  error: "/error",
  // Auth
  login: "/login",
  register: "/register",
  contact: "/contact",
  profile: "/profile",
  // emailReset: "/email/reset",
  // emailForgot: "/email/forgot",
  // emailConfirm: "/email/confirm",
  // Container
  containerAssignments: "/container/assignments",
  containerAssignment: (id) => `/container/assignments/${id}`,
  createContainerAssignment: "/container/assignments/create",
  editContainerAssignment: (id) => `/container/assignments/${id}/edit`,
  // Item id
  itemIdAssignments: "/itemid/assignments",
  itemIdAssignment: (id) => `/itemid/assignments/${id}`,
  createItemIdAssignment: "/itemid/assignments/create",
  editItemIdAssignment: (id) => `/itemid/assignments/${id}/edit`,
  // Default
  defaultAssignments: "/default/assignments",
  defaultAssignment: (id) => `/default/assignments/${id}`,
  createDefaultAssignment: "/default/assignments/create",
  editDefaultAssignment: (id) => `/default/assignments/${id}/edit`,
  // GTIN
  gtinOverview: "/gtins",
  gtinCreate: "/gtins/create",
  gtinEdit: (id) => `/gtins/${id}/edit`,
  gtinSeriesOverview: (id) => `/gtins/${id}/series`,
  gtinSeriesCreate: (id) => `/gtins/${id}/series/create`,
  // GRAI
  graiOverview: "/grais",
  graiCreate: "/grais/create",
  graiEdit: (id) => `/grais/${id}/edit`,
  graiSeriesOverview: (id) => `/grais/${id}/series`,
  graiSeriesCreate: (id) => `/grais/${id}/series/create`,
  // Device manager
  devices: "/devices",
  deviceSettings: (id) => `/devices/${id}`,
  // QuickView
  summary: "/quickview/summary",
  history: "/quickview/history",
  fullScanMode: "/quickview/fullScanMode"
};

export default routes;
