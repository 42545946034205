import { useState, useEffect, useRef, useMemo } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeOff } from "@fortawesome/free-solid-svg-icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import moment from "moment";

import client from "../../client";
import PageLoading from "../../components/PageLoading";
import beep from "../../assets/audio/friendly_shortbeep.wav";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import IconToggleButton from "../../components/IconToggleButton";
import BorderSpinner from "../../serialization/components/BorderSpinner";

function getReadsTotalCount(reads) {
  return reads?.length > 0
    ? reads.map((_) => _.count).reduce((a, b) => a + b)
    : 0;
}

function getIsSameYearMonthDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export default function Summary() {
  const [reads10min, setReads10min] = useState(undefined);
  const [reads24h, setReads24h] = useState(undefined);
  const [reads7d, setReads7d] = useState(undefined);

  const [chartData, setChartData] = useState(undefined);

  const [devices, setDevices] = useState(undefined);
  const [selectedDevices, setSelectedDevices] = useState([]);

  let { current: deviceChanged } = useRef(false);
  const beepAudio = useMemo(() => new Audio(beep), []);
  const [isAudioMuted, setIsAudioMuted] = useState(
    !!localStorage.getItem("isAudioMuted")
  );

  const intervalId = useRef(undefined);

  const fetchDevices = async () => {
    setDevices(await client.devices.list());
  };

  const fetchReads = async () => {
    const reads = await Promise.all([
      client.readers.getApprovedReadCountSum({
        readerIds: selectedDevices.map((device) => device.value),
        timeDelta: "10m",
      }),
      client.readers.getApprovedReadCountSum({
        readerIds: selectedDevices.map((device) => device.value),
        timeDelta: "24h",
      }),
      client.readers.getApprovedReadCountSum({
        readerIds: selectedDevices.map((device) => device.value),
        timeDelta: "past7dates",
      }),
    ]);

    // The reads10min is not available in setInterval
    setReads10min((reads10min) => {
      const prevRead10minCount = getReadsTotalCount(reads10min);
      const read10minCount = getReadsTotalCount(reads[0]);

      // Get latest isAudioMuted
      setIsAudioMuted((isAudioMuted) => {
        if (
          !isAudioMuted &&
          !deviceChanged &&
          reads10min !== undefined &&
          read10minCount > prevRead10minCount
        ) {
          console.log("beep!");
          beepAudio.play();
        }
        return isAudioMuted;
      });

      deviceChanged = false;

      return reads[0];
    });
    setReads24h(reads[1]);
    setReads7d(reads[2]);
  };

  const fetchReadsData = async () => {
    const readsData = await client.readers.getApprovedReadCount({
      readerIds: selectedDevices.map((device) => device.value),
      timeDelta: "8d",
    });

    const date1 = new Date(); // today
    const date2 = new Date(new Date().setDate(date1.getDate() - 1)); // yesterday
    const date3 = new Date(new Date().setDate(date1.getDate() - 2));
    const date4 = new Date(new Date().setDate(date1.getDate() - 3));
    const date5 = new Date(new Date().setDate(date1.getDate() - 4));
    const date6 = new Date(new Date().setDate(date1.getDate() - 5));
    const date7 = new Date(new Date().setDate(date1.getDate() - 6));

    const data = [
      {
        name: moment(date7).format("YY-MM-DD"),
        value: 0,
      },
      {
        name: moment(date6).format("YY-MM-DD"),
        value: 0,
      },
      {
        name: moment(date5).format("YY-MM-DD"),
        value: 0,
      },
      {
        name: moment(date4).format("YY-MM-DD"),
        value: 0,
      },
      {
        name: moment(date3).format("YY-MM-DD"),
        value: 0,
      },
      {
        name: moment(date2).format("YY-MM-DD"),
        value: 0,
      },
      {
        name: moment(date1).format("YY-MM-DD"),
        value: 0,
      },
    ];

    for (const read of readsData) {
      if (getIsSameYearMonthDay(date7, read.measuredAt)) {
        data[0].value += read.count;
      } else if (getIsSameYearMonthDay(date6, read.measuredAt)) {
        data[1].value += read.count;
      } else if (getIsSameYearMonthDay(date5, read.measuredAt)) {
        data[2].value += read.count;
      } else if (getIsSameYearMonthDay(date4, read.measuredAt)) {
        data[3].value += read.count;
      } else if (getIsSameYearMonthDay(date3, read.measuredAt)) {
        data[4].value += read.count;
      } else if (getIsSameYearMonthDay(date2, read.measuredAt)) {
        data[5].value += read.count;
      } else if (getIsSameYearMonthDay(date1, read.measuredAt)) {
        data[6].value += read.count;
      }
    }

    console.log("Data: ", data);
    setChartData(data);
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  useEffect(() => {
    setReads10min(undefined);
    setChartData(undefined);
    fetchReads();
    fetchReadsData();

    // Clear previously set interval
    clearInterval(intervalId.current);

    intervalId.current = setInterval(async () => {
      await fetchReads();
    }, 3000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [selectedDevices]);

  useUpdateEffect(() => {
    deviceChanged = true;
  }, [selectedDevices]);

  if (
    devices === undefined ||
    reads10min === undefined ||
    reads24h === undefined ||
    reads7d === undefined
  ) {
    return <PageLoading />;
  }

  const read10minCount = getReadsTotalCount(reads10min);
  const read24hCount = getReadsTotalCount(reads24h);
  const read7dCount = getReadsTotalCount(reads7d);

  return (
    <div>
      <h4 className="fw-bolder mt-4 mb-4">Summary</h4>
      <div className="mb-2">
        <IconToggleButton
          on={!isAudioMuted}
          onToggle={() => {
            // Sync local storage
            localStorage.setItem("isAudioMuted", !isAudioMuted ? "1" : "");
            // Sync state
            setIsAudioMuted(!isAudioMuted);
          }}
          iconOn={<FontAwesomeIcon icon={faVolumeHigh} />}
          iconOff={<FontAwesomeIcon icon={faVolumeOff} />}
        />
      </div>
      <div className="mb-3">
        <form>
          <label className="form-label" htmlFor="devicesSelect">
            Devices
          </label>

          <Select
            inputId="devicesSelect"
            defaultValue={selectedDevices}
            options={devices.map((device) => ({
              value: device.id,
              label: `${device.macAddress} (${device.location})`,
            }))}
            onChange={(options) => setSelectedDevices(options)}
            menuPlacement="bottom"
            isMulti
          />
        </form>
      </div>
      <div className="d-flex gap-2 mb-5">
        <div className="bg-white rounded-3 p-3">
          <div>Last 10 min</div>
          <div>{read10minCount}</div>
        </div>
        <div className="bg-white rounded-3 p-3">
          <div>Last 24 h</div>
          <div>{read24hCount}</div>
        </div>
        <div className="bg-white rounded-3 p-3">
          <div>Last 7 d</div>
          <div>{read7dCount}</div>
        </div>
      </div>

      {chartData ? (
        <div>
          {" "}
          <LineChart
            width={950}
            height={400}
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <XAxis dataKey="name" />
            <YAxis dataKey="value" />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Line
              dataKey="value"
              strokeWidth={3}
              stroke="var(--bs-blue)"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </div>
      ) : (
        <BorderSpinner />
      )}
    </div>
  );
}
