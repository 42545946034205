export class NotFoundError extends Error {}

export class NotAuthenticatedError extends Error {}

export class BadRequestError extends Error {
  constructor(data) {
    super();
    this.data = data;
  }
}

export class CreateContainerAssignmentError extends BadRequestError {
  constructor(data) {
    super();
    this.data = {
      elementString: data.element_string || [],
      warehouse: data.warehouse || [],
      containerAlias: data.container_alias || [],
      bookingType: data.booking_type || [],
      readerMac: data.reader_mac || [],
    };
  }
}

export class ContainerAssignmentError extends BadRequestError {
  constructor(data) {
    super();
    this.data = {
      elementString: data.element_string || [],
      containerAlias: data.container_alias || [],
    };
  }
}

export class CreateItemIdAssignmentError extends BadRequestError {
  constructor(data) {
    super();
    this.data = {
      itemId: data.item_id || [],
    };
  }
}

export class ItemIdAssignmentError extends BadRequestError {
  constructor(data) {
    super();
    this.data = {
      itemId: data.item_id || [],
    };
  }
}

export class RegisterError extends BadRequestError {
  constructor(data) {
    super(data);

    // Errors specific to particular fields
    this.username = data.username || [];
    this.password = data.password || [];
    this.company = data.company || [];
    this.email = data.email || [];
    this.nonFieldErrors = data.non_field_errors || [];
  }
}

export class FormError extends BadRequestError {
  constructor(data) {
    super(data);

    this.nonFieldErrors = data.non_field_errors || [];
  }
}

export class GtinSeriesError extends FormError {
  constructor(data) {
    super(data);

    this.gtin = data.gtin || []; // query string filtering error
    // TODO: other fields
  }
}

export class GraiSeriesError extends FormError {
  constructor(data) {
    super(data);

    this.grai = data.grai || [];
  }
}
