import { useNavigate } from "react-router-dom";

import Logo from "../components/Logo";

export default function ApplicationError() {
  const navigate = useNavigate();

  return (
    <div className="jumbotron">
      <Logo />
      <h3 className="mb-3">Something went wrong</h3>
      <p>
        If the error persists, please contact administration for
        troubleshooting.
      </p>
      <button className="btn btn-primary" onClick={() => navigate(-1)}>
        Go back
      </button>
    </div>
  );
}
