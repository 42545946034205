class Company {
  constructor(data) {
    this.name = data.name;
    this.street = data.street;
    this.postalCode = data.postal_code;
    this.city = data.city;
    this.country = data.country;
    this.gln = data.gln;
    this.phone = data.phone;
    this.contactPerson = data.contact_person;
  }
}

class User {
  constructor(data) {
    this.id = data.id;
    this.username = data.username;
    this.email = data.email;
    this.company = data.company ? new Company(data.company) : null;
    this.permissions = data.user_permissions;
    // this.lastLogin = new Date(data.last_login);
    // this.dateJoined = new Date(data.date_joined);
    // this.isActive = data.is_active;
    // this.isStaff = data.is_staff;
    // this.isSuperuser = data.is_superuser;
  }
}

class PaginatedList {
  constructor(data) {
    this.count = data.count;
    this.next = data.next;
    this.previous = data.previous;
    this.results = data.results;
  }
}

class ReadingCount {
  constructor(data) {
    this.measuredAt = new Date(data.measured_at_date);
    this.readerId = data.reader_id;
    this.count = data.count;
  }
}

export {
  User as UserModel,
  PaginatedList as PaginatedListModel,
  ReadingCount as ReadingCountModel,
};
