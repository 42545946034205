import { reverse } from "../../client/constants";
import {
  BadRequestError,
  FormError,
  GraiSeriesError,
  GtinSeriesError,
} from "../../client/errors";
import { Resource } from "../../client/resources";
import {
  GtinListModel,
  GtinModel,
  GtinSeriesListModel,
  GtinSeriesModel,
  GraiListModel,
  GraiModel,
  GraiSeriesListModel,
  GraiSeriesModel,
} from "../client/models";

export class Gtins extends Resource {
  async list(filters = {}) {
    const {
      page = 1,
      pageSize = 10,
      ordering = { id: "createdAt", desc: true },
    } = filters;

    // Convert ordering object javascript name to API name
    let orderingName = undefined;
    if (ordering.id === "createdAt") {
      orderingName = "created_at";
    } else if (ordering.id === "totalSerials") {
      orderingName = "total_serials";
    } else {
      orderingName = ordering.id; // name, ...
    }

    const response = await this._instance.get(reverse("gtinList"), {
      params: {
        page,
        page_size: pageSize,
        ...(ordering && {
          ordering: `${ordering.desc ? "-" : ""}${orderingName}`,
        }),
      },
    });

    return new GtinListModel(response.data);
  }

  async create({
    name,
    packageIndicator,
    companyPrefix,
    productRef,
    checkDigit,
    filterValue,
  }) {
    try {
      await this._instance.post(reverse("gtinList"), {
        name,
        package_indicator: packageIndicator,
        company_prefix: companyPrefix,
        product_ref: productRef,
        check_digit: checkDigit,
        filter_value: filterValue,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new FormError(error.data);
      }
      throw error;
    }
  }

  async retrieve(id) {
    const response = await this._instance.get(reverse("gtinDetail", { id }));

    return new GtinModel(response.data);
  }

  async update(id, { name, filterValue }) {
    await this._instance.patch(reverse("gtinDetail", { id }), {
      name,
      filter_value: filterValue,
    });
  }

  async destroy(id) {
    await this._instance.delete(reverse("gtinDetail", { id }));
  }
}

class GtinSeries extends Resource {
  async list(filters = {}) {
    try {
      const {
        page = 1,
        pageSize = 10,
        ordering = "-end_serial",
        gtin = null,
      } = filters;

      const response = await this._instance.get(reverse("gtinSeriesList"), {
        params: { page, page_size: pageSize, ordering, ...(gtin && { gtin }) },
      });

      return new GtinSeriesListModel(response.data);
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new GtinSeriesError(error.data); // only in create and list (user-based)
      }
      throw error;
    }
  }

  async create({
    gtin,
    startSerial,
    endSerial,
    note,
    label1,
    label2,
    label3,
    seriesFilterValue = null,
    outputRowFactor = 1,
  }) {
    await this._instance.post(reverse("gtinSeriesList"), {
      gtin,
      start_serial: startSerial,
      end_serial: endSerial,
      note,
      label1,
      label2,
      label3,
      ...(seriesFilterValue && { series_filter_value: seriesFilterValue }),
      output_row_factor: outputRowFactor,
    });
  }

  async destroy(id) {
    await this._instance.delete(reverse("gtinSeriesDetail", { id }));
  }

  async update(id, { note }) {
    const response = await this._instance.patch(
      reverse("gtinSeriesDetail", { id }),
      { note }
    );
    return new GtinSeriesModel(response.data);
  }

  async export(id) {
    const response = await this._instance.post(
      reverse("gtinSeriesDetailExport", { id }),
      null,
      { headers: { accept: "text/csv" } }
    );

    return response.data; // return text contents
  }

  async purge(ids) {
    // Only purge if ids is not null/undefined
    if (ids) {
      await this._instance.delete(reverse("gtinSeriesList"), {
        params: { id__in: ids.toString() },
      });
    }
  }
}

class Grais extends Resource {
  async list(filters = {}) {
    const {
      page = 1,
      pageSize = 10,
      ordering = { id: "createdAt", desc: true },
    } = filters;

    let orderingName = undefined;
    if (ordering.id === "createdAt") {
      orderingName = "created_at";
    } else if (ordering.id === "totalSerials") {
      orderingName = "total_serials";
    } else {
      orderingName = ordering.id;
    }

    const response = await this._instance.get(reverse("graiList"), {
      params: {
        page,
        page_size: pageSize,
        ...(ordering && {
          ordering: `${ordering.desc ? "-" : ""}${orderingName}`,
        }),
      },
    });

    return new GraiListModel(response.data);
  }

  async create({ name, companyPrefix, assetType, checkDigit, filterValue }) {
    try {
      await this._instance.post(reverse("graiList"), {
        name,
        company_prefix: companyPrefix,
        asset_type: assetType,
        check_digit: checkDigit,
        filter_value: filterValue,
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new FormError(error.data);
      }
      throw error;
    }
  }

  async retrieve(id) {
    const response = await this._instance.get(reverse("graiDetail", { id }));
    return new GraiModel(response.data);
  }

  async update(id, { name, filterValue }) {
    await this._instance.patch(reverse("graiDetail", { id }), {
      name,
      filter_value: filterValue,
    });
  }

  async destroy(id) {
    await this._instance.delete(reverse("graiDetail", { id }));
  }
}

class GraiSeries extends Resource {
  async list(filters = {}) {
    try {
      const {
        page = 1,
        pageSize = 10,
        ordering = "-end_serial",
        grai = null,
      } = filters;

      const response = await this._instance.get(reverse("graiSeriesList"), {
        params: { page, page_size: pageSize, ordering, ...(grai && { grai }) },
      });

      return new GraiSeriesListModel(response.data);
    } catch (error) {
      if (error instanceof BadRequestError) {
        throw new GraiSeriesError(error.data);
      }
      throw error;
    }
  }

  async destroy(id) {
    await this._instance.delete(reverse("graiSeriesDetail", { id }));
  }

  async update(id, { note }) {
    const response = await this._instance.patch(
      reverse("graiSeriesDetail", { id }),
      { note }
    );
    return new GraiSeriesModel(response.data);
  }

  async export(id) {
    const response = await this._instance.post(
      reverse("graiSeriesDetailExport", { id }),
      null,
      { headers: { accept: "text/csv" } }
    );

    return response.data;
  }

  async purge(ids) {
    if (ids) {
      await this._instance.delete(reverse("graiSeriesList"), {
        params: { id__in: ids.toString() },
      });
    }
  }

  async create({
    grai,
    startSerial,
    endSerial,
    note,
    label1,
    label2,
    label3,
    seriesFilterValue = null,
    outputRowFactor = 1,
    aliasPrefix = "",
    aliasStartIncrement = null,
    aliasSuffix = "",
  }) {
    await this._instance.post(reverse("graiSeriesList"), {
      grai,
      start_serial: startSerial,
      end_serial: endSerial,
      note,
      label1,
      label2,
      label3,
      ...(seriesFilterValue && { series_filter_value: seriesFilterValue }),
      output_row_factor: outputRowFactor,
      // Alias defaults
      alias_prefix: aliasPrefix,
      alias_start_increment: aliasStartIncrement,
      alias_suffix: aliasSuffix,
    });
  }
}

export {
  GtinSeries as GtinSeriesResource,
  Grais as GraisResource,
  GraiSeries as GraiSeriesResource,
};
