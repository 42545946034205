import { PaginatedListModel as PaginatedList } from "../../client/models";

// General class for GTIN and GRAI serial numbers
class Serial {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.companyId = data.company_id;
    this.companyPrefix = data.company_prefix;
    // packageIndicator + productRef
    // leadingZero + assetType
    this.checkDigit = data.check_digit;
    this.filterValue = data.filter_value;
    this.createdAt = new Date(data.createdAt);

    // Computed
    this.partition = data.partition;
    this.totalSerials = data.total_serials;
    this.seriesCount = data.series_count;
    this.companyPrefixLength = data.company_prefix_length;
  }
}

export const FilterValues = [
  { value: 0, label: "All other" },
  { value: 1, label: "Point of Sale" },
  { value: 2, label: "Case" },
  { value: 4, label: "Inner Pack" },
  { value: 5, label: "Uni load" },
  { value: 7, label: "Component" },
];

class Series {
  constructor(data) {
    this.id = data.id;
    this.startSerial = data.start_serial;
    this.endSerial = data.end_serial;
    this.totalSerials = data.total_serials;
    this.note = data.note;
    this.label1 = data.label1;
    this.label2 = data.label2;
    this.label3 = data.label3;
    this.createdAt = new Date(data.created_at);
    this.seriesFilterValue = data.series_filter_value;
    this.outputRowFactor = data.output_row_factor;
  }
}

// GTINs
//

class Gtin extends Serial {
  constructor(data) {
    super(data);
    this.packageIndicator = data.package_indicator;
    this.productRef = data.product_ref;
    // Computed
    this.gtin = data.gtin;
    this.itemReference = data.item_reference;
  }
}

class GtinList extends PaginatedList {
  constructor(data) {
    super(data);
    this.results = data.results.map((gtin) => new Gtin(gtin));
  }
}

class GtinSeries extends Series {
  constructor(data) {
    super(data);
    this.gtin = data.gtin;
  }
}

class GtinSeriesList extends PaginatedList {
  constructor(data) {
    super(data);
    this.results = data.results.map((gtinSeries) => new GtinSeries(gtinSeries));
  }
}

// GRAIs
//

class Grai extends Serial {
  constructor(data) {
    super(data);

    this.leadingZero = data.leading_zero;
    this.assetType = data.asset_type;
    this.grai = data.grai;
  }
}

class GraiList extends PaginatedList {
  constructor(data) {
    super(data);

    this.results = data.results.map((result) => new Grai(result));
  }
}

class GraiSeries extends Series {
  constructor(data) {
    super(data);

    this.grai = data.grai;

    // Currently not displayed in the frontend
    this.aliasPrefix = data.alias_prefix;
    this.aliasStartIncrement = data.alias_start_increment;
    this.aliasSuffix = data.alias_suffix;
  }
}

class GraiSeriesList extends PaginatedList {
  constructor(data) {
    super(data);

    this.results = data.results.map((result) => new GraiSeries(result));
  }
}

export {
  GtinList as GtinListModel,
  Gtin as GtinModel,
  GtinSeriesList as GtinSeriesListModel,
  GtinSeries as GtinSeriesModel,
  GraiList as GraiListModel,
  Grai as GraiModel,
  GraiSeriesList as GraiSeriesListModel,
  GraiSeries as GraiSeriesModel,
};
