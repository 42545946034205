import { Component } from "react";
import { Navigate } from "react-router-dom";

import routes from "../routes";

// Handling critical errors (white screen) in component rendering
// - event handling errors => toasts (not related to UI)
// - rendering errors => redirect to error page (related to UI)
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
      return <Navigate to={routes.error} replace />;
    }

    return this.props.children;
  }
}
