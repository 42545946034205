import { createContext, useState, useEffect, useContext } from "react";

import client, { NotAuthenticatedError } from "../client";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(undefined);

  const fetchUser = async () => {
    try {
      setUser(await client.authentication.user());
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        setUser(null);
        return;
      }
      throw error;
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const login = async ({ username, password }) => {
    await client.authentication.login({ username, password });
    await fetchUser();
  };

  const logout = async () => {
    client.authentication.logout();
    setUser(null);
  };

  const updateEmail = async (newEmail) => {
    await client.authentication.update({ email: newEmail });
    setUser({ ...user, email: newEmail });
  };

  const auth = { user, login, logout, updateEmail };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
