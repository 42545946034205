import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";

export default function Layout() {
  return (
    <div className="vh-100 vw-100 row">
      <Sidebar />
      <div className="main col-9 px-5 pb-4">
        <Header />
        <Outlet />
      </div>
    </div>
  );
}
