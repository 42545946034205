import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

import client, { NotAuthenticatedError } from "../../client";
import { Table, PlaceHolderTable } from "../components";
import routes from "../../routes";
import { useAuth } from "../../hooks/AuthProvider";

export default function DefaultAssignments() {
  const auth = useAuth();

  const [defaultAssignments, setDefaultAssignments] = useState(undefined);
  // Store filter state outside <Table> component because it will be unmounted on filter change
  // and the state will be lost
  const [filters, setFilters] = useState({
    globalFilter: null,
    pageSize: 10,
    pageIndex: 1,
  });
  const [selectedAssignmentIds, setSelectedAssignmentIds] = useState([]);
  const [showDeleteSelectedModal, setShowDeleteSelectedModal] = useState(false);

  const fetchItemIdAssignments = async () => {
    setDefaultAssignments(undefined);

    const { globalFilter, pageIndex, pageSize } = filters || {};
    try {
      setDefaultAssignments(
        await client.defaultAssignments.list({
          search: globalFilter,
          itemId: "DEFAULT",
          page: pageIndex,
          pageSize,
        })
      );
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    fetchItemIdAssignments();
  }, [filters]);

  const handleTableFiltersChange = ({ globalFilter, pageSize, pageIndex }) => {
    setFilters({ globalFilter, pageSize, pageIndex });
  };

  const handleTableRowSelectChange = (selectedRowIds) => {
    setSelectedAssignmentIds(selectedRowIds);
  };

  const handleDeleteSelected = async () => {
    try {
      await client.defaultAssignments.purge(selectedAssignmentIds);
      fetchItemIdAssignments();
      setShowDeleteSelectedModal(false);
      setSelectedAssignmentIds([]);

      return;
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      console.error(error);
      throw error;
    }
  };

  // Convert state to table data/columns
  //

  const data =
    defaultAssignments &&
    defaultAssignments.results.map((defaultAssignments) => ({
      id: defaultAssignments.id,
      itemId: defaultAssignments.itemId,
      warehouseShortcode: defaultAssignments.warehouseShortcode,
      bookingType: defaultAssignments.bookingType,
      action: (
        <Action
          id={defaultAssignments.id}
          onDelete={() => fetchItemIdAssignments()}
        />
      ),
    }));

  const columns = useMemo(() => [
    { Header: "Item ID", accessor: "itemId" },
    { Header: "Warehouse Short Code", accessor: "warehouseShortcode" },
    { Header: "Booking Type", accessor: "bookingType" },
    { Header: "", accessor: "action" },
  ]);

  // Rendering
  //

  const renderTable = () => {
    if (defaultAssignments === undefined) {
      return (
        <PlaceHolderTable
          headers={columns.map((column) => column.Header)}
          size={filters.pageSize}
        />
      );
    }

    const pageCount = Math.ceil(defaultAssignments.count / filters.pageSize);

    const deleteAllButton = (
      <button
        onClick={() => {
          if (selectedAssignmentIds.length > 0) {
            setShowDeleteSelectedModal(true);
          }
        }}
        className="btn btn-primary"
        disabled={selectedAssignmentIds.length <= 0}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    );
    return (
      <Table
        data={data}
        columns={columns}
        controlledGlobalFilter={filters.globalFilter}
        controlledPageSize={filters.pageSize}
        controlledPageIndex={filters.pageIndex}
        controlledPageCount={pageCount}
        onChange={handleTableFiltersChange}
        onRowSelectChange={handleTableRowSelectChange}
        footer={deleteAllButton}
      />
    );
  };

  return (
    <>
      <div className="default-assignments">
        <h4 className="fw-bolder mt-4 mb-4">Default Assignment</h4>
        <Link
          to={routes.createDefaultAssignment}
          className="btn btn-primary mb-4"
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Create assignment
        </Link>
        {renderTable()}
      </div>

      <Modal
        show={showDeleteSelectedModal}
        onHide={() => setShowDeleteSelectedModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete selected item Id assignments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {selectedAssignmentIds.length}{" "}
          assignments?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShowDeleteSelectedModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(handleDeleteSelected, {
                pending: "Deleting selected item ID assignments",
                error: "Error while deleting selected item Id assignments",
                success: "Selected item ID assignments were deleted",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function Action({ id, onDelete }) {
  const auth = useAuth();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = async () => {
    try {
      await client.itemIdAssignments.destroy(id);
      onDelete();
      return;
    } catch (error) {
      if (error instanceof NotAuthenticatedError) {
        auth.logout();
        return;
      }
      console.error(error);
      throw error;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Link to={routes.editDefaultAssignment(id)}>
          <FontAwesomeIcon icon={faPen} color="black" />
        </Link>
        <FontAwesomeIcon
          icon={faTrash}
          className="hover-cursor--pointer"
          onClick={() => setShowDeleteModal(true)}
        />
      </div>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete item ID assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this default assignment?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              toast.promise(handleDelete, {
                pending: "Deleting item Id assignment",
                error: "Error while deleting item ID assignment",
                success: "Item ID assignment was deleted",
              })
            }
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
